import React from 'react';
import PrivateRoute from 'shared/auth/private-route';
import CalibrationDeliveryForm from './CalibrationDeliveryForm';
import CalibrationLevelForm from './CalibrationLevelForm';
import Calibrations from './calibrations';
export const newCalibrationsDeliveryTo = '/calibrations/new-delivery';
export const newCalibrationsLevelTo = '/calibrations/new-level';
export const CalibrationsTo = '/calibrations';

const Routes = () => (
  <div>
    <PrivateRoute
      path={CalibrationsTo}
      exact
      component={Calibrations}
      resource="Calibrations"
      operation={['READ']}
    />
    <PrivateRoute
      path={newCalibrationsDeliveryTo}
      exact
      component={CalibrationDeliveryForm}
      resource="Calibrations"
      operation={['UPDATE']}
    />
    <PrivateRoute
      path={newCalibrationsLevelTo}
      exact
      component={CalibrationLevelForm}
      resource="Calibrations"
      operation={['UPDATE']}
    />
  </div>
);

export default Routes;
