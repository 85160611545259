import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl } from 'config/env';
import { AppThunk } from 'config/store';
import { Moment } from 'moment';

import { ICalibration } from 'shared/model/calibration.model';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import { convertDateFromServer } from 'shared/utils/date-utils';
import { errorNotification } from './notifierSlice';
import isTruthy from 'shared/utils/isTruthy';

const initialState = {
  loading: false,
  errorMessage: '',
  calibrations: [] as ICalibration[]
};

type Params = {
  start_date?: string | Moment;
  type?: 'delivery' | 'level' | 'null' | undefined;
  group_ids?: string;
  source?: string;
  excludeNanolike: boolean;
  created_by_id?: string;
};

export type CalibrationsState = typeof initialState;

export const slice = createSlice({
  name: 'calibrations',
  initialState,
  reducers: {
    fetchCalibrationsInfoStart: state => {
      state.loading = true;
      state.errorMessage = '';
    },
    fetchCalibrationsInfoFailed: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    fetchCalibrationsInfoSuccess: (state, action: PayloadAction<ICalibration[]>) => {
      action.payload.forEach(item => {
        item.calibration_date = convertDateFromServer(item.calibration_date as string);
      });
      state.loading = false;
      state.calibrations = action.payload;
    }
  }
});

export default slice.reducer;

//Actions
const { fetchCalibrationsInfoStart, fetchCalibrationsInfoFailed, fetchCalibrationsInfoSuccess } =
  slice.actions;

const apiUrl = getEnvApiUrl();

export const fetchCalibrationsInfo =
  (
    start_date: Moment,
    typeFilter: 'delivery' | 'level' | 'null',
    group_ids: string[],
    created_by_id: string[]
  ): AppThunk =>
  async dispatch => {
    const constructParams = () => {
      const hasExternal = created_by_id.includes('external');
      const createdByUserIds = created_by_id.filter(id => id !== 'external');

      const params: Params = {
        start_date: start_date.toISOString(),
        excludeNanolike: true
      };

      const source = [
        !(hasExternal && createdByUserIds.length === 0) && 'manual,delivery',
        (hasExternal || createdByUserIds.length === 0) && 'external,export'
      ]
        .filter(isTruthy)
        .join(',');
      params.source = source;

      if (typeFilter !== 'null' && typeFilter !== undefined) {
        params.type = typeFilter;
      }

      if (group_ids.length > 0) {
        params.group_ids = group_ids.join(',');
      }

      if (createdByUserIds.length) params.created_by_id = createdByUserIds.join(',');

      return params;
    };

    try {
      dispatch(fetchCalibrationsInfoStart());
      const params = constructParams();
      const response: AxiosResponse<ICalibration[]> = await axios.get(
        `${apiUrl}/v2/calibrations?${new URLSearchParams(params).toString()}`
      );
      dispatch(fetchCalibrationsInfoSuccess(response.data));
    } catch (error) {
      console.error('Calibration Info Fetch Error:', error);
      const errorMsg = getRequestErrorMessage(error);
      dispatch(fetchCalibrationsInfoFailed(errorMsg));
      dispatch(errorNotification(errorMsg));
    }
  };
