import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IRootState } from 'config/store';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import PrivateComponent from 'shared/auth/privateComponent';
import { ReactComponent as AddGraphIcon } from 'shared/icons/CreateGraph.svg';
import { fetchDashboard } from 'shared/reducers/dashboardSlice';
import Loading from 'shared/widgets/loading';
import DashboardToolbar from './dashboardToolbar';
import NanoDashboardGrid from './grid/NanoDashboardGrid';
import { fetchGraphs } from 'shared/reducers/graphsSlice';
import isTruthy from 'shared/utils/isTruthy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fill: 'white',
      width: '20px',
      height: '30px',
      marginRight: theme.spacing(1)
    }
  })
);

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id: dashboardId } = useParams<{ id: string }>();

  // const fetchingDashboard = useSelector(({ dashboard }: IRootState) => dashboard.loading);
  const dashboard = useSelector(({ dashboard }: IRootState) =>
    dashboard.dashboards.find(d => d.dashboard_id === dashboardId)
  );

  const fetchingGraphs = useSelector(({ graphs }: IRootState) => graphs.loading);
  const graphs = useSelector(
    ({ graphs }: IRootState) =>
      (dashboard?.graph_ids || [])
        .map(graphId => graphs.graphs.find(graph => graph.graph_id === graphId))
        .filter(isTruthy),
    shallowEqual
  );
  const isLoadingGraphs = fetchingGraphs && graphs.length !== dashboard?.graph_ids.length;

  useEffect(() => {
    if (dashboardId) {
      dispatch(fetchDashboard(dashboardId));
    }
  }, [dispatch, dashboardId]);

  const graphIds = useMemo(() => dashboard?.graph_ids || [], [dashboard]);

  useEffect(() => {
    if (graphIds.length > 0) {
      dispatch(fetchGraphs(graphIds));
    }
  }, [dispatch, graphIds]);

  return (
    <Box>
      {!dashboard ? (
        <Box p={4}>
          <Loading />
        </Box>
      ) : (
        <>
          <DashboardToolbar dashboard={dashboard} />
          <Box overflow="auto" height="calc(100vh - 100px)">
            {isLoadingGraphs ? (
              <Box p={4}>
                <Loading />
              </Box>
            ) : graphs.length > 0 ? (
              <NanoDashboardGrid dashboard={dashboard} graphs={graphs} />
            ) : (
              <PrivateComponent resource="Graph" operation={['CREATE']}>
                <Box pt={4} textAlign="center">
                  <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    component={Link}
                    to={`/dashboard/${dashboardId}/graph/new`}
                    title={t('create_graph')}
                  >
                    <AddGraphIcon className={classes.icon} />
                    {t('create_graph')}
                  </Button>
                </Box>
              </PrivateComponent>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Dashboard;
