import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from '@material-ui/core';
import { ReactComponent as BatteryProblemIcon } from 'shared/icons/BatteryProblemIcon.svg';
import { Warning, AcUnit, WifiOff, Build, Power } from '@material-ui/icons';
import {
  isOperationSuggested,
  OPERATION_SUGGESTED,
  OperationSuggested
} from 'shared/model/operationSuggested';

const OPERATION_SUGGESTED_ICON = {
  [OPERATION_SUGGESTED.CONNECT_SENSOR]: Power,
  [OPERATION_SUGGESTED.MOVE]: Build,
  [OPERATION_SUGGESTED.NEW_BATTERY]: BatteryProblemIcon,
  [OPERATION_SUGGESTED.NEW_DEVICE_FULL]: Build,
  [OPERATION_SUGGESTED.NEW_ELEC]: Build,
  [OPERATION_SUGGESTED.NEW_SENSOR]: Build,
  [OPERATION_SUGGESTED.OUT_OF_DATA]: WifiOff,
  [OPERATION_SUGGESTED.OUT_OF_DATA_STATION]: WifiOff,
  // [OPERATION_SUGGESTED.REINSTALL_SAME_LEG]: Build,
  [OPERATION_SUGGESTED.SHIM]: Build,
  [OPERATION_SUGGESTED.TRAPPED_IN_ICE]: AcUnit
} as Readonly<{ [key in OperationSuggested]: React.ElementType }>;

const OPERATION_SUGGESTED_SHORT = {
  [OPERATION_SUGGESTED.CONNECT_SENSOR]: 'device_status_problem_connect_sensor_short',
  [OPERATION_SUGGESTED.MOVE]: 'device_status_problem_move_short',
  [OPERATION_SUGGESTED.NEW_BATTERY]: 'device_status_problem_new_battery_short',
  [OPERATION_SUGGESTED.NEW_DEVICE_FULL]: 'device_status_problem_new_device_full_short',
  [OPERATION_SUGGESTED.NEW_ELEC]: 'device_status_problem_new_elec_short',
  [OPERATION_SUGGESTED.NEW_SENSOR]: 'device_status_problem_new_sensor_short',
  [OPERATION_SUGGESTED.OUT_OF_DATA]: 'device_status_problem_out_of_data_short',
  [OPERATION_SUGGESTED.OUT_OF_DATA_STATION]: 'device_status_problem_out_of_data_station_short',
  // [OPERATION_SUGGESTED.REINSTALL_SAME_LEG]: 'device_status_problem_reinstall_same_leg_short',
  [OPERATION_SUGGESTED.SHIM]: 'device_status_problem_shim_short',
  [OPERATION_SUGGESTED.TRAPPED_IN_ICE]: 'device_status_problem_trapped_in_ice_short'
} as Readonly<Record<OperationSuggested, string>>;

const OPERATION_SUGGESTED_TITLE = {
  [OPERATION_SUGGESTED.CONNECT_SENSOR]: 'device_status_problem_connect_sensor_title',
  [OPERATION_SUGGESTED.MOVE]: 'device_status_problem_move_title',
  [OPERATION_SUGGESTED.NEW_BATTERY]: 'device_status_problem_new_battery_title',
  [OPERATION_SUGGESTED.NEW_DEVICE_FULL]: 'device_status_problem_new_device_full_title',
  [OPERATION_SUGGESTED.NEW_ELEC]: 'device_status_problem_new_elec_title',
  [OPERATION_SUGGESTED.NEW_SENSOR]: 'device_status_problem_new_sensor_title',
  [OPERATION_SUGGESTED.OUT_OF_DATA]: 'device_status_problem_out_of_data_title',
  [OPERATION_SUGGESTED.OUT_OF_DATA_STATION]: 'device_status_problem_out_of_data_station_title',
  // [OPERATION_SUGGESTED.REINSTALL_SAME_LEG]: 'device_status_problem_reinstall_same_leg_title',
  [OPERATION_SUGGESTED.SHIM]: 'device_status_problem_shim_title',
  [OPERATION_SUGGESTED.TRAPPED_IN_ICE]: 'device_status_problem_trapped_in_ice_title'
} as Readonly<Record<OperationSuggested, string>>;

const OPERATION_SUGGESTED_EXPLANATION = {
  [OPERATION_SUGGESTED.CONNECT_SENSOR]: 'device_status_problem_connect_sensor_explanation',
  [OPERATION_SUGGESTED.MOVE]: 'device_status_problem_move_explanation',
  [OPERATION_SUGGESTED.NEW_BATTERY]: 'device_status_problem_new_battery_explanation',
  [OPERATION_SUGGESTED.NEW_DEVICE_FULL]: 'device_status_problem_new_device_full_explanation',
  [OPERATION_SUGGESTED.NEW_ELEC]: 'device_status_problem_new_elec_explanation',
  [OPERATION_SUGGESTED.NEW_SENSOR]: 'device_status_problem_new_sensor_explanation',
  [OPERATION_SUGGESTED.OUT_OF_DATA]: 'device_status_problem_out_of_data_explanation',
  [OPERATION_SUGGESTED.OUT_OF_DATA_STATION]:
    'device_status_problem_out_of_data_station_explanation',
  // [OPERATION_SUGGESTED.REINSTALL_SAME_LEG]: 'device_status_problem_reinstall_same_leg_explanation',
  [OPERATION_SUGGESTED.SHIM]: 'device_status_problem_shim_explanation',
  [OPERATION_SUGGESTED.TRAPPED_IN_ICE]: 'device_status_problem_trapped_in_ice_explanation'
} as Readonly<Record<OperationSuggested, string>>;

const useOperationSuggestedTitle = (operation_suggested: string) => {
  const { t } = useTranslation();
  if (isOperationSuggested(operation_suggested)) {
    return t(OPERATION_SUGGESTED_TITLE[operation_suggested]);
  } else {
    return t('device_status_problem_unknown_title');
  }
};

const useOperationSuggestedShort = (operation_suggested: string) => {
  const { t } = useTranslation();
  if (isOperationSuggested(operation_suggested)) {
    return t(OPERATION_SUGGESTED_SHORT[operation_suggested]);
  } else {
    return t('device_status_problem_unknown_short', { product: 'SiloConnect' });
  }
};

const useOperationSuggestedExplanation = (operation_suggested: string) => {
  const { t } = useTranslation();
  if (isOperationSuggested(operation_suggested)) {
    return t(OPERATION_SUGGESTED_EXPLANATION[operation_suggested]);
  } else {
    return t('device_status_problem_unknown_explanation', { product: 'SiloConnect' });
  }
};

const useOperationSuggestedIcon = (operation_suggested: string) => {
  if (isOperationSuggested(operation_suggested)) {
    return OPERATION_SUGGESTED_ICON[operation_suggested];
  } else {
    return Warning;
  }
};

function useOperationSuggestedInfo(operation_suggested: string | null | undefined) {
  const title = useOperationSuggestedTitle(operation_suggested ?? '');
  const short = useOperationSuggestedShort(operation_suggested ?? '');
  const explanation = useOperationSuggestedExplanation(operation_suggested ?? '');
  const Icon = useOperationSuggestedIcon(operation_suggested ?? '');

  if (!operation_suggested) {
    return {};
  } else {
    return { title, short, Icon, explanation };
  }
}

const OperationSuggestedChip = ({ operation_suggested }: { operation_suggested: string }) => {
  const { short, Icon, explanation } = useOperationSuggestedInfo(operation_suggested);

  if (!short || !Icon || !explanation) return null;

  return (
    <Tooltip title={explanation}>
      <Typography
        color="error"
        variant="body2"
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 5,
          flexDirection: 'row',
          whiteSpace: 'normal'
        }}
      >
        <Icon fontSize="inherit" width="15px" height="15px" /> <span>{short}</span>
      </Typography>
    </Tooltip>
  );
};

export {
  OPERATION_SUGGESTED,
  isOperationSuggested,
  useOperationSuggestedInfo,
  OperationSuggestedChip
};
