import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { IRootState } from 'config/store';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IDashboard } from 'shared/model/dashboard.model';
import { createDashboard, updateDashboard } from 'shared/reducers/dashboardSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    content: {
      [theme.breakpoints.up('sm')]: {
        minWidth: '400px',
        minHeight: '150px'
      }
    }
  })
);

export interface ICreateOrEditDashBoardDialogProps {
  dashboard?: IDashboard;
  onSuccess?: Function;
}

const CreateOrEditDashBoardDialog = (props: ICreateOrEditDashBoardDialogProps) => {
  const isNew = props.dashboard ? false : true;
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const updating = useSelector(({ dashboard }: IRootState) => dashboard.updating);
  const updateSuccess = useSelector(({ dashboard }: IRootState) => dashboard.updateSuccess);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { register, handleSubmit, errors } = useForm<{
    dashboardName: string;
  }>({
    defaultValues: {
      dashboardName: isNew ? '' : props.dashboard?.name
    }
  });

  const toggleDialog = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    if (updateSuccess === true) {
      setOpen(false);
    }
  }, [updateSuccess]);

  const onSubmit = handleSubmit(({ dashboardName }) => {
    const name = dashboardName.trim();

    if (isNew) {
      // create dashboard
      dispatch(createDashboard({ name }));
    } else {
      dispatch(updateDashboard(props.dashboard as IDashboard, { name }));
    }
    if (props.onSuccess) {
      props.onSuccess();
    }
  });

  const titleKey = isNew ? 'create_dashboard' : 'edit_dashboard';

  return (
    <>
      <Button variant="contained" color="primary" size="small" onClick={toggleDialog}>
        {isNew ? <AddIcon /> : <EditIcon />}
      </Button>
      <Dialog fullScreen={fullScreen} open={open} onClose={toggleDialog}>
        <form className={classes.root} onSubmit={onSubmit} autoComplete="off">
          <DialogTitle>{t(titleKey)}</DialogTitle>
          <DialogContent className={classes.content} dividers>
            <TextField
              autoFocus
              margin="dense"
              id="dashboardName"
              label={t('dashboard_name')}
              fullWidth
              name="dashboardName"
              disabled={updating}
              inputRef={register({
                validate: value =>
                  (value && value.trim() !== '') || (
                    <Trans i18nKey="required_field">Required Field</Trans>
                  )
              })}
              inputProps={{ maxLength: 64 }}
              error={errors.dashboardName ? true : false}
              helperText={errors.dashboardName && errors.dashboardName.message}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialog} startIcon={<CancelIcon />}>
              {t('cancel')}
            </Button>
            <Button type="submit" color="primary" startIcon={<SaveIcon />}>
              {t('save')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CreateOrEditDashBoardDialog;
