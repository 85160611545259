import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { IRootState } from 'config/store';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ILabelValueOption } from 'shared/utils/select-utils';
import { getDeviceTypeOptions } from 'shared/widgets/devices/deviceType';
import MultipleAutoComplete from 'shared/widgets/form/multipleAutocomplete';
import SelectDevicesOrGroups from 'shared/widgets/form/selectDevicesOrGroups';
import validateRequired from 'shared/widgets/form/validateRequired';

export interface IAlertFormFirstStepProps {
  step: number;
  activeStep: number;
}

const AlertFormFirstStep = (props: IAlertFormFirstStepProps) => {
  const { activeStep, step } = props;
  const isActiveStep = activeStep === step;
  const { t } = useTranslation();
  const allGroups = useSelector(({ group }: IRootState) => group.groups);
  const { register, errors, watch } = useFormContext();

  const groupGroup = t('group');

  const deviceTypeOptions = useMemo(() => getDeviceTypeOptions(allGroups, t), [allGroups, t]);
  const selectedGroupOrDevices = watch<string, ILabelValueOption>('device_group_to_check');

  const atLeastOneGroup = selectedGroupOrDevices?.some(
    (option: ILabelValueOption) => option.group === groupGroup
  );

  return (
    <Box display={activeStep === step ? 'block' : 'none'} marginBottom={4}>
      <Box textAlign="center" py={1}>
        {t(`aggregation_simple_desc`)}
      </Box>

      <TextField
        autoFocus
        margin="dense"
        id="alert_name"
        label={t('choose_alert_name')}
        fullWidth
        name="alert_name"
        inputRef={register({
          validate: isActiveStep ? validateRequired : undefined
        })}
        error={errors.alert_name ? true : false}
        helperText={errors?.alert_name?.message}
      />
      <SelectDevicesOrGroups
        name="device_group_to_check"
        label={t('select_group_or_device')}
        step={step}
        activeStep={activeStep}
        disabled={false}
        selectAll={false}
      />
      {deviceTypeOptions.length > 1 && atLeastOneGroup && (
        <MultipleAutoComplete
          name="device_types"
          label={t('device_type')}
          options={deviceTypeOptions}
          validate={isActiveStep ? validateRequired : undefined}
        />
      )}
    </Box>
  );
};

export default AlertFormFirstStep;
