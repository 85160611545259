import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { IRootState } from 'config/store';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { createGroup, updateGroup } from 'shared/reducers/groupSlice';
import { usePrevious } from 'shared/utils/react-utils';
import { ILabelValueOption } from 'shared/utils/select-utils';
import Loading from 'shared/widgets/loading';
import { workspaceIsSilo } from 'shared/utils/workspace-utils';
import GroupForm from './groupForm';
import { IDevice } from 'shared/model/device.model';
import axios, { AxiosResponse } from 'axios';
import { IGroup } from 'shared/model/group.model';
import { getEnvApiUrl } from 'config/env';

export interface IGroupFormResponse {
  group_name: string;
  client_poi_id: string;
  sold_to: string;
  devices: ILabelValueOption<IDevice[]>[];
  location?: any;
}

const CreateOrEditGroup = (props: {
  id?: string;
  editShiptoSoldtoMode: boolean;
  onClose?: () => void;
}) => {
  const dispatch = useDispatch();
  const updateSuccess = useSelector(({ group }: IRootState) => group.updateSuccess);
  const previousUpdateSuccess = usePrevious(updateSuccess);
  const loadingDevices = useSelector(({ devices }: IRootState) => devices.loading);
  // const group = useSelector(({ group }: IRootState) => group.group);
  const [group, setGroup] = useState<IGroup | null>(null);
  const loading = useSelector(({ group }: IRootState) => group.loading);
  const history = useHistory();
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isSilo = workspaceIsSilo(settings);
  const urlId = useParams<{ id: string }>();
  const id = props.id || urlId.id;
  const isNew = id === 'new';

  const { onClose } = props;
  const close = useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      history.push('/groups');
    }
  }, [history, onClose]);

  useEffect(() => {
    async function fetchData() {
      if (id && !isNew) {
        const response: AxiosResponse<IGroup> = await axios.get(
          `${getEnvApiUrl()}/v1/groups/${id}/`
        );
        setGroup(response.data);
      }
    }
    fetchData();
  }, [dispatch, id, isNew]);

  useEffect(() => {
    if (previousUpdateSuccess === false && updateSuccess === true) {
      close();
    }
  }, [close, previousUpdateSuccess, updateSuccess]);

  const onSubmit = (responses: IGroupFormResponse) => {
    const groupFromResponse = getGroupFromResponse(responses);
    if (isNew) {
      dispatch(createGroup({ ...groupFromResponse, is_poi: isSilo ? false : true }));
    } else {
      const toUpdate = {
        group_id: group?.group_id,
        ...groupFromResponse,
        client_poi_id:
          groupFromResponse?.client_poi_id === '' ? null : groupFromResponse?.client_poi_id?.trim()
      };
      dispatch(updateGroup(toUpdate));
    }
  };

  return (
    <Box p={1}>
      <Paper elevation={3}>
        {loadingDevices || loading ? (
          <Box p={4}>
            <Loading />
          </Box>
        ) : (
          <>
            {(isNew || group) && (
              <GroupForm
                onSubmit={onSubmit}
                group={group}
                isNew={isNew}
                onClose={close}
                editShiptoSoldtoMode={props.editShiptoSoldtoMode}
              />
            )}
          </>
        )}
      </Paper>
    </Box>
  );
};

export default CreateOrEditGroup;

const getGroupFromResponse = (responses: IGroupFormResponse) => {
  const group: any = {
    ...responses,
    group_name: responses.group_name?.trim(),
    client_poi_id: responses.client_poi_id?.trim(),
    sold_to: responses?.sold_to?.trim()
  };

  group.devices = responses.devices
    ? responses.devices.map(item => ({ device_id: item.value }))
    : [];

  return group;
};
