// moment locales
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/ja';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/vi';
import 'moment/locale/en-gb';
import 'moment/locale/sv';
import 'moment/locale/pl';
import { sortByLabel } from './data-utils';
import { IWorkspaceSetting } from 'shared/model/workspace.model';
import { workspaceIsIbc } from './workspace-utils';
import i18next, { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { IMe } from 'shared/model/user.model';
import { IRootState } from 'config/store';
import { isNumber } from 'lodash';
import { nanolikeDataType } from 'shared/model/api.model';
import config from 'config/config';

// https://www.gnu.org/savannah-checkouts/gnu/gettext/manual/gettext.html#Language-Codes

export const supportedLangs = [
  'en',
  'fr',
  'es',
  'it',
  'de',
  'pt',
  'ja',
  'pl',
  'vi',
  'zh-TW',
  'zh-CN',
  'sv'
];

export const getLangOptions = () => {
  const flagMap: { [key: string]: string } = {
    en: '🇬🇧',
    fr: '🇫🇷',
    es: '🇪🇸',
    it: '🇮🇹',
    de: '🇩🇪',
    pt: '🇧🇷',
    ja: '🇯🇵',
    pl: '🇵🇱',
    vi: '🇻🇳',
    'zh-TW': '🇹🇼',
    'zh-CN': '🇨🇳',
    sv: '🇸🇪'
  };

  const options = supportedLangs.map(lang => {
    //@ts-ignore
    let languageNames = new Intl.DisplayNames([lang], { type: 'language' });
    //@ts-ignore
    return {
      value: lang,
      label: `${flagMap[lang]} ${
        languageNames.of(lang)
          ? languageNames.of(lang)[0].toUpperCase() + languageNames.of(lang)?.slice(1)
          : lang
      }`
    };
  });
  options.sort(sortByLabel);
  return options;
};

/**
 * Check if Intl supports unit
 * @param {string} unit
 * @returns {boolean}
 */
function isUnitSupportedByIntl(unit?: string): boolean {
  return Intl.supportedValuesOf('unit').includes(unit ?? '');
}

/**
 * Hook returns data type with unit and manage if data type is kilogram
 * Remove this logic when there are no longer any kilogram alerts on siloconnect workspaces.
 */
export const useLocalizedDataTypeWithUnit = () => {
  const { t } = useTranslation();
  const { localizedUnit } = useLocalizedUnit();

  const localizedDataTypeWithUnit = useCallback(
    (dataType: nanolikeDataType): string => {
      let label: string = config.dataTypeWithDynamicFillingUnit.includes(dataType)
        ? t('string_workspace_filling_unit', { value: t(dataType) })
        : t(dataType);
      if (dataType === 'level_kg') {
        label = `${t(dataType)} (${localizedUnit('kilogram', 'long')})`;
      }
      return label;
    },
    [t, localizedUnit]
  );

  return { localizedDataTypeWithUnit };
};

/**
 * Extract units localized from Intl if exists
 * @param {TFunction} t
 * @param {string | undefined} locale
 * @param {string} unit
 * @param {"short" | "long" | "narrow" | undefined} unitDisplay
 * @returns {string}
 */
const getLocalizedUnit = (
  t: TFunction,
  locale: string | undefined,
  unit: string,
  unitDisplay?: 'short' | 'long' | 'narrow' | undefined
): string => {
  if (isUnitSupportedByIntl(unit)) {
    const format = new Intl.NumberFormat(locale, {
      style: 'unit',
      unit: unit,
      unitDisplay: unitDisplay
    });
    return format.formatToParts().find(part => part.type === 'unit')?.value ?? t(unit);
  }
  return t(unit);
};

/**
 * Hook extracts units localized from Intl if exists
 */
export const useLocalizedUnit = () => {
  const me = useSelector(({ authentication }: IRootState) => authentication.me) as IMe;
  const { t } = useTranslation();

  const localizedUnit = useCallback(
    (unit: string, unitDisplay?: 'short' | 'long' | 'narrow'): string | undefined => {
      const locale = me.configuration.preferred_language;
      return getLocalizedUnit(t, locale, unit, unitDisplay);
    },
    [me.configuration.preferred_language, t]
  );

  return { localizedUnit };
};

export const setupI18nextFormatters = (settings: IWorkspaceSetting[]) => {
  // Fetch fillingUnit in workspace settings
  const fillingUnit = settings.find(s => s.key === 'fillingUnit')?.value;
  const isIbc = workspaceIsIbc(settings);

  /**
   * Custom formatter to format number and add the unit for data_type
   * `value` must be an object like this : { value: 2.3, unit: 'pound }
   */
  i18next.services.formatter?.add('format_data_type_unit', (value, lng, options) => {
    if (!isNumber(value?.value)) return '-';

    const unit = value.unit;

    if (isUnitSupportedByIntl(unit)) {
      return value.value.toLocaleString(lng, { ...options, style: 'unit', unit: unit });
    } else {
      const formattedValue = value.value.toLocaleString(lng, options);
      return `${formattedValue} ${unit === 'ton' ? 't' : unit ?? ''}`;
    }
  });

  /**
   * Custom formatter to format number and add the workspace unit
   * examples: '12 t' / '234 lb' / '22 l / '22 🍆'
   * usage in code : t('number_workspace_filling_unit', {value : 23})
   */
  i18next.services.formatter?.add(
    'format_number_with_workspace_filling_unit',
    (value, lng, options) => {
      if (!isNumber(value)) return '-';

      const isSupportedUnit = fillingUnit && isUnitSupportedByIntl(fillingUnit);

      if (isSupportedUnit || (!fillingUnit && isIbc)) {
        return value.toLocaleString(lng, {
          ...options,
          style: 'unit',
          unit: fillingUnit || 'liter'
        });
      } else {
        const formattedValue = value.toLocaleString(lng, options);
        const unit = fillingUnit || 't';
        return `${formattedValue} ${unit === 'ton' ? 't' : unit}`;
      }
    }
  );

  /**
   * Custom formatter to add the workspace unit at the end of the string
   * examples: 'Quantity (ton)' / 'Quantity (pound)' / 'Quantity (liter)' / 'Quantity (🍆)'
   * usage in code : t('string_workspace_filling_unit', { value: t('level')})
   */
  i18next.services.formatter?.addCached('format_string_with_workspace_filling_unit', lng => {
    const unit = fillingUnit ?? (isIbc ? 'liter' : 'ton');
    return val => `${val} (${getLocalizedUnit(i18next.t, lng, unit, 'long')})`;
  });
};
