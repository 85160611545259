import { TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import { IRootState } from 'config/store';
import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IGroup, Location } from 'shared/model/group.model';
import { getDeviceLabel } from 'shared/utils/device-utils';
import { workspaceIsSilo, workspaceUseZonda } from 'shared/utils/workspace-utils';
import SelectDevices from 'shared/widgets/form/selectDevices';
import { IGroupFormResponse } from './createOrEditGroup';
import LocationAutocomplete from 'shared/widgets/map/LocationAutocomplete';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useGroupAutoOrder } from 'shared/hooks/autoOrders';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    },
    title: {
      textAlign: 'center'
    },
    stepper: {
      padding: '1rem',
      paddingBottom: '0.5rem',
      width: '50vw',
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.up('lg')]: {
        width: '40vw'
      },
      [theme.breakpoints.down('sm')]: {
        width: '80%'
      }
    },
    content: {
      width: '100%',
      minHeight: '40vh'
    },
    btnbar: {
      '&>*': {
        marginRight: theme.spacing(1)
      }
    },
    divider: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    btnDivider: {
      marginLeft: theme.spacing(2)
    },
    details: {
      flexDirection: 'column',
      gap: 8
    }
  })
);

const GroupForm = (props: {
  onSubmit: (responses: IGroupFormResponse) => void;
  group: IGroup | null;
  isNew: boolean;
  onClose?: () => void;
  editShiptoSoldtoMode?: boolean;
}) => {
  const { group, isNew = !group, editShiptoSoldtoMode } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const updating = useSelector(({ group }: IRootState) => group.updating);
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isSilo = workspaceIsSilo(settings);
  const useZonda = workspaceUseZonda(settings);
  const [location, setLocation] = useState<Location | null>(group?.location || null);

  const initialValues = !isNew && group ? initDefaultValues(group, isSilo) : ({} as any);

  const form = useForm<IGroupFormResponse>({
    defaultValues: initialValues
  });
  // Access dirtyFields to subscribe to its changes.
  const dirtyFields = form.formState.dirtyFields;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hasAutoOrder = Boolean(group && useGroupAutoOrder(group.group_id));

  const onSubmit = form.handleSubmit(responses => props.onSubmit({ ...responses, location }));

  const title = isNew ? t('add_group') : t('edit_group');

  return (
    <FormContext {...form}>
      <form className={classes.root} autoComplete="off">
        <Box className={classes.title}>
          <Typography variant="h5">{title}</Typography>
        </Box>
        <Grid container justify="center" alignItems="center">
          <Grid item className={classes.content} xs={12} md={6} lg={4}>
            <Box mb={2}>
              <TextField
                autoFocus
                margin="dense"
                label={t('group_name')}
                fullWidth
                name="group_name"
                inputRef={form.register({
                  validate: value => {
                    if (value.length === 0) {
                      return <Trans i18nKey="required_field">Required Field</Trans>;
                    }
                    return true;
                  }
                })}
                error={form.errors.group_name ? true : false}
                helperText={form.errors.group_name && form.errors.group_name.message}
                disabled={editShiptoSoldtoMode || updating}
              />
            </Box>

            <Box
              mb={2}
              style={{
                // Hide the field, but keep it so that the form has the correct values
                display: editShiptoSoldtoMode || group?.is_poi ? 'none' : 'block'
              }}
            >
              <SelectDevices
                name="devices"
                label={t('select_device', { count: 100 })}
                disabled={updating}
                selectAll
              />
            </Box>

            {isSilo ? (
              <>
                <Box mb={2}>
                  <TextField
                    margin="dense"
                    label={useZonda ? t('ship_to') : t('client_poi_id')}
                    fullWidth
                    name="client_poi_id"
                    type="text" // avoid autofill as email
                    inputRef={form.register()}
                    disabled={updating}
                    helperText={
                      form.errors.client_poi_id ? (
                        form.errors.client_poi_id.message
                      ) : hasAutoOrder && dirtyFields.has('client_poi_id') ? (
                        <span style={{ color: theme.palette.warning.main }}>
                          {t('warning_auto_orders_impacted')}
                        </span>
                      ) : null
                    }
                  />
                </Box>

                {useZonda && (
                  <Box mb={2}>
                    <TextField
                      margin="dense"
                      label={t('sold_to')}
                      fullWidth
                      name="sold_to"
                      inputRef={form.register()}
                      disabled={updating}
                      helperText={
                        form.errors.sold_to ? (
                          form.errors.sold_to.message
                        ) : hasAutoOrder && dirtyFields.has('sold_to') ? (
                          <span style={{ color: theme.palette.warning.main }}>
                            {t('warning_auto_orders_impacted')}
                          </span>
                        ) : null
                      }
                    />
                  </Box>
                )}
              </>
            ) : (
              <Accordion defaultExpanded={Boolean(group?.location)}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>{t('add_optionnal_address')}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <LocationAutocomplete onLocationChanged={setLocation} location={location} />
                </AccordionDetails>
              </Accordion>
            )}
          </Grid>
        </Grid>
        <Divider variant="middle" className={classes.divider} />
        <Box display="flex" justifyContent="center" alignItems="center" className={classes.btnbar}>
          <Button
            onClick={props.onClose}
            startIcon={<CancelIcon />}
            variant="contained"
            disabled={updating}
          >
            <Hidden xsDown>{t('cancel')}</Hidden>
          </Button>
          <Divider orientation="vertical" className={classes.btnDivider} />
          <Button
            color="primary"
            startIcon={<SaveIcon />}
            variant="contained"
            disabled={updating}
            onClick={onSubmit}
          >
            <Hidden xsDown>{t('save')}</Hidden>
          </Button>
        </Box>
      </form>
    </FormContext>
  );
};

export default GroupForm;

/**
 * Init form with default values if exists
 * @param callback
 * @param devices
 * @param groups
 * @param t
 */
const initDefaultValues = (group: IGroup, isSilo: boolean) => {
  const deviceOptions = group.devices
    ? group.devices.map(aDevice => {
        return {
          label: getDeviceLabel(aDevice, isSilo),
          value: aDevice.device_id
        };
      })
    : [];

  return {
    ...group,
    devices: deviceOptions
  };
};
