import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { IRootState } from 'config/store';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PrivateComponent from 'shared/auth/privateComponent';
import { IGroup } from 'shared/model/group.model';
import { deleteGroup, fetchGroups } from 'shared/reducers/groupSlice';
import { fetchUsers } from 'shared/reducers/usersSlice';
import { workspaceUseZonda } from 'shared/utils/workspace-utils';
import ConfirmDelete from 'shared/widgets/confirmDelete';
import DataTable from 'shared/widgets/dataTable';
import TitleWithSearchField from 'shared/widgets/titleWithSearchField';
import UsersAvatar from 'shared/widgets/usersAvatar';
import GroupDetails from './groupDetails';
import RoomIcon from '@material-ui/icons/Room';
import { Icon } from '@material-ui/core';
import { useIsAuthorised } from 'shared/auth/auth-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: 'none',
      color: 'unset',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    contextToolBar: {
      '&>*': {
        marginLeft: theme.spacing(1)
      }
    },
    icon: {
      width: '32px',
      height: '32px'
    }
  })
);

const Groups = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const groups = useSelector(({ group }: IRootState) => group.groups);
  const users = useSelector(({ users }: IRootState) => users.users);
  const loading = useSelector(({ group }: IRootState) => group.loading);
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const useZonda = workspaceUseZonda(settings);

  const [selectedRows, setSelectedRows] = useState<IGroup[]>([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [filterText, setFilterText] = React.useState('');

  const columns: IDataTableColumn<IGroup>[] = useMemo(() => {
    const defs: (IDataTableColumn<IGroup> | undefined)[] = [
      {
        selector: 'group_name',
        name: t('group_name'),
        sortable: true,
        grow: 1,
        format: (row: IGroup) => (
          <Box display="flex" alignItems="center">
            {row.is_poi ? <RoomIcon /> : <Icon />}
            <Box p={1}>
              <Box fontWeight="fontWeightBold" data-tag="allowRowEvents">
                {row.group_name}
              </Box>
            </Box>
          </Box>
        )
      },
      {
        selector: 'devices',
        name: t('device', { count: 100 }),
        grow: 1,
        sortable: true,
        hide: 'sm',
        format: (row: IGroup) => (
          <Box data-tag="allowRowEvents">
            {t('deviceWithCount', { count: row.devices ? row.devices?.length : 0 })}
          </Box>
        )
      },
      {
        selector: 'users_in_group',
        name: t('member', { count: 100 }),
        grow: 1,
        sortable: true,
        hide: 'sm',
        format: (group: IGroup) => {
          const members = users.filter(aUser =>
            aUser.group_memberships.some(
              aMembership => aMembership.group.group_id === group.group_id
            )
          );
          return <UsersAvatar users={members} />;
        }
      },
      {
        selector: 'client_poi_id',
        name: useZonda ? t('ship_to') : t('client_poi_id'),
        grow: 1,
        sortable: true,
        hide: 'sm',
        format: (row: IGroup) => (
          <Box display="flex" alignItems="center" data-tag="allowRowEvents">
            {row.client_poi_id?.trim()}
          </Box>
        )
      },
      useZonda
        ? {
            selector: 'sold_to',
            name: t('sold_to'),
            grow: 1,
            sortable: true,
            hide: 'sm',
            format: (row: IGroup) => (
              <Box display="flex" alignItems="center" data-tag="allowRowEvents">
                {row.sold_to?.trim()}
              </Box>
            )
          }
        : undefined
    ];

    return defs.filter(c => c !== undefined) as IDataTableColumn<IGroup>[];
  }, [t, users, useZonda]);

  useEffect(() => {
    if (users.length === 0) {
      dispatch(fetchUsers());
    }
  }, [dispatch, users.length]);

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const actions = (
    <PrivateComponent resource="Group" operation={['CREATE']}>
      <Button color="primary" variant="contained" component={Link} to="/group/new" size="small">
        <AddIcon />
      </Button>
    </PrivateComponent>
  );

  const contextActions = useMemo(() => {
    const onSuccess = () => {
      dispatch(fetchGroups());
      setToggleCleared(!toggleCleared);
    };

    const handleDelete = async () => {
      for (let i = 0; i < selectedRows.length; i++) {
        await dispatch(deleteGroup(selectedRows[i]));
      }
      onSuccess();
    };

    return (
      <Box className={classes.contextToolBar}>
        {selectedRows.length === 1 && (
          <>
            <PrivateComponent resource="Group" operation={['UPDATE']}>
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to={`/group/${selectedRows[0].group_id}`}
                size="small"
              >
                <EditIcon />
              </Button>
              {/*<EditDeviceDialog device={selectedRows[0]} onSuccess={onSuccess} />*/}
            </PrivateComponent>
          </>
        )}
        <PrivateComponent resource="Group" operation={['DELETE']}>
          <ConfirmDelete onConfirm={handleDelete} objectToReturn={selectedRows} size="small" />
        </PrivateComponent>
      </Box>
    );
  }, [classes.contextToolBar, dispatch, selectedRows, toggleCleared]);

  const title = React.useMemo(() => {
    const onChange = (filter: string) => {
      setFilterText(filter);
      if (filter.length === 0) {
        setResetPaginationToggle(!resetPaginationToggle);
      }
    };

    return (
      <TitleWithSearchField title={t('group', { count: 100 })} onChange={onChange} autoFocus />
    );
  }, [resetPaginationToggle, t]);

  const data =
    groups.length > 0
      ? groups.filter(group => {
          const name = group.group_name.toLocaleLowerCase();
          const sold_to = group.sold_to ? group.sold_to.toLocaleLowerCase() : '';
          const client_poi_id = group.client_poi_id ? group.client_poi_id.toLocaleLowerCase() : '';
          const filter = filterText.toLocaleLowerCase();
          return (
            name.includes(filter) || sold_to.includes(filter) || client_poi_id.includes(filter)
          );
        })
      : groups;
  return (
    <Box p={1}>
      <DataTable
        title={title}
        columns={columns}
        data={data}
        selectableRows={useIsAuthorised('Group', ['DELETE', 'UPDATE'])}
        defaultSortField="group_name"
        progressPending={loading}
        expandableRows
        expandableRowsComponent={<GroupDetails />}
        expandOnRowClicked
        expandableRowsHideExpander
        onSelectedRowsChange={handleRowSelected}
        actions={actions}
        contextActions={contextActions}
        clearSelectedRows={toggleCleared}
      />
    </Box>
  );
};

export default Groups;
