// Possible values stored in Device.metadata.warning_cause
const WARNING_CAUSE = {
  ICE: 'ice'
} as const;

type WarningCause = (typeof WARNING_CAUSE)[keyof typeof WARNING_CAUSE];

const isWarningCause = (() => {
  const warnings = Object.values(WARNING_CAUSE);

  return (warning_cause: any): warning_cause is WarningCause => warnings.includes(warning_cause);
})();

export { WARNING_CAUSE, isWarningCause };
export type { WarningCause };
