// Possible values stored in Device.metadata.operation_suggested
const OPERATION_SUGGESTED = {
  CONNECT_SENSOR: 'connect_sensor',
  MOVE: 'move',
  NEW_BATTERY: 'new_battery',
  NEW_DEVICE_FULL: 'new_device_full',
  NEW_ELEC: 'new_elec',
  NEW_SENSOR: 'new_sensor',
  // NEW_TAPPING: 'new_tapping',
  OUT_OF_DATA: 'out_of_data',
  OUT_OF_DATA_STATION: 'out_of_data_station',
  // REINSTALL_SAME_LEG: 'reinstall_same_leg',
  SHIM: 'shim',
  TRAPPED_IN_ICE: 'trapped_in_ice'
} as const;

type OperationSuggested = (typeof OPERATION_SUGGESTED)[keyof typeof OPERATION_SUGGESTED];

const isOperationSuggested = (() => {
  const operations = Object.values(OPERATION_SUGGESTED);

  return (operation_suggested: any): operation_suggested is OperationSuggested =>
    operations.includes(operation_suggested);
})();

export { OPERATION_SUGGESTED, isOperationSuggested };
export type { OperationSuggested };
