import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography, useTheme } from '@material-ui/core';
import { Warning, AcUnit } from '@material-ui/icons';
import { isWarningCause, WARNING_CAUSE, WarningCause } from 'shared/model/warningCause';

const WARNING_CAUSE_ICON = {
  [WARNING_CAUSE.ICE]: AcUnit
} as Readonly<{ [key in WarningCause]: React.ElementType }>;

const WARNING_CAUSE_TITLE = {
  [WARNING_CAUSE.ICE]: 'device_warning_cause_ice_title'
} as Readonly<Record<WarningCause, string>>;

const WARNING_CAUSE_SHORT = {
  [WARNING_CAUSE.ICE]: 'device_warning_cause_ice_title'
} as Readonly<Record<WarningCause, string>>;

const WARNING_CAUSE_EXPLANATION = {
  [WARNING_CAUSE.ICE]: 'device_warning_cause_ice_explanation'
} as Readonly<Record<WarningCause, string>>;

const useWarningCauseTitle = (warning_cause: string) => {
  const { t } = useTranslation();
  if (isWarningCause(warning_cause)) {
    return t(WARNING_CAUSE_TITLE[warning_cause]);
  } else {
    return t('device_status_problem_unknown_title');
  }
};

const useWarningCauseShort = (warning_cause: string) => {
  const { t } = useTranslation();
  if (isWarningCause(warning_cause)) {
    return t(WARNING_CAUSE_SHORT[warning_cause]);
  } else {
    return t('device_status_problem_unknown_short', { product: 'SiloConnect' });
  }
};

const useWarningCauseExplanation = (warning_cause: string) => {
  const { t } = useTranslation();
  if (isWarningCause(warning_cause)) {
    return t(WARNING_CAUSE_EXPLANATION[warning_cause]);
  } else {
    return t('device_status_problem_unknown_explanation', { product: 'SiloConnect' });
  }
};

const useWarningCauseIcon = (warning_cause: string) => {
  if (isWarningCause(warning_cause)) {
    return WARNING_CAUSE_ICON[warning_cause];
  } else {
    return Warning;
  }
};

function useWarningCauseInfo(warning_cause: string | null | undefined) {
  const title = useWarningCauseTitle(warning_cause ?? '');
  const short = useWarningCauseShort(warning_cause ?? '');
  const explanation = useWarningCauseExplanation(warning_cause ?? '');
  const Icon = useWarningCauseIcon(warning_cause ?? '');

  if (!warning_cause) {
    return {};
  } else {
    return { title, short, Icon, explanation };
  }
}

const WarningCauseChip = ({ warning_cause }: { warning_cause: string }) => {
  const { short, Icon, explanation } = useWarningCauseInfo(warning_cause);
  const theme = useTheme();

  if (warning_cause === 'ice') return null; // Ignored for now for SiloConnect to avoid false positive until we'll have our first real case

  if (!short || !Icon || !explanation) return null;

  return (
    <Tooltip title={explanation}>
      <Typography
        variant="body2"
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 5,
          flexDirection: 'row',
          whiteSpace: 'normal',
          color: theme.palette.warning.main
        }}
      >
        <Icon fontSize="inherit" width="15px" height="15px" /> <span>{short}</span>
      </Typography>
    </Tooltip>
  );
};

export { WARNING_CAUSE, isWarningCause, useWarningCauseInfo, WarningCauseChip };
