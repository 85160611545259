import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import useConfigTheme from '../../config/theme';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 *
 */
const LeftLanding = () => {
  const theme = useTheme();
  const configTheme = useConfigTheme();
  const HeaderMenuLogo = configTheme.headerMenuLogo;
  const Ray = configTheme.ray;

  return (
    <Hidden xsDown>
      <Grid
        sm={6}
        style={{
          color: '#FFF',
          display: 'flex',
          alignItems: 'center',
          background: `url(${configTheme.backgroundImage}), rgb(15, 11, 55)`,
          backgroundBlendMode: 'hard-light',
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: 'right',
          backgroundPositionY: 'top',
          position: 'relative',
          padding: theme.spacing(3) // Added padding to create space around the content
        }}
      >
        <HeaderMenuLogo
          style={{
            position: 'absolute',
            top: theme.spacing(3),
            left: theme.spacing(3),
            minWidth: '30%', // Adjust the width as needed
            height: 'auto' // Maintain aspect ratio
          }}
        />
        <Grid style={{ display: 'flex', alignItems: 'center' }}>
          <Box
            style={{
              display: 'flex',
              alignItems: 'start',
              flexDirection: 'column'
            }}
          >
            <Box style={{ textAlign: 'left', maxWidth: '80%' }}>
              <Typography variant="h3" style={{ fontWeight: 700 }}>
                {configTheme.welcomeText}
              </Typography>
              <Typography variant="h6" style={{ marginBottom: theme.spacing(1) }}>
                {configTheme.welcomeDescription}
              </Typography>
              <Ray />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Hidden>
  );
};

LeftLanding.propTypes = propTypes;
LeftLanding.defaultProps = defaultProps;
// #endregion

export default LeftLanding;
