import { FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IRootState } from 'config/store';
import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IGraph } from 'shared/model/graph.model';
import { sortByLabel } from 'shared/utils/data-utils';
import { ILabelValueOption } from 'shared/utils/select-utils';
import MultipleAutoComplete from 'shared/widgets/form/multipleAutocomplete';
import {
  IFilterCriterionDef,
  getFilterOperatorFormKey,
  getFilterValueFormKey
} from './filterGraph.model';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center'
    },
    dataFilter: {
      width: '120px',
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1)
    }
  })
);

interface IFilterCriterionProps {
  def: IFilterCriterionDef;
  graph: IGraph;
}

const OperatorSelect = ({ def }: { def: IFilterCriterionDef }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const operators: ILabelValueOption[] = def.operators.map(item => ({
    label: t(`filter_${item}`),
    value: item
  }));
  const formKey = getFilterOperatorFormKey(def.key);

  return (
    <FormControl size="small">
      <Controller
        control={control}
        name={formKey}
        as={
          <Select style={{ flexGrow: 1 }}>
            {operators.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        }
      />
    </FormControl>
  );
};

const FilterCriterion = ({ def }: IFilterCriterionProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control } = useFormContext();
  const deviceContents = useSelector(({ product }: IRootState) => product.products);
  const groups = useSelector(({ group }: IRootState) => group.groups);
  const formKey = getFilterValueFormKey(def.key);

  const valueOptions: ILabelValueOption[] = useMemo(() => {
    if (def.key === 'device_content_id') {
      return deviceContents
        .map(item => ({ label: item.device_content, value: item.id }))
        .sort(sortByLabel);
    } else if (def.key === 'group_ids') {
      return groups
        .map(item => ({ label: item.group_name, value: item.group_id }))
        .sort(sortByLabel);
    } else if (def.valueOptions) {
      return def.valueOptions.map(item => ({ label: t(item.i18n), value: item.value }));
    } else {
      return [];
    }
  }, [def.key, deviceContents, groups, t, def.valueOptions]);

  const inputProps = def.valueType === 'number_positive' ? { min: 0 } : {};

  return (
    <Box className={classes.root}>
      <Box className={classes.dataFilter}>{t(def.i18n ? def.i18n : def.key)}</Box>
      <Box flexGrow="0">{def.operators.length > 0 && <OperatorSelect def={def} />}</Box>
      <Box flexGrow="1">
        {def.valueType === 'multiselect' ? (
          <MultipleAutoComplete fullWidth name={formKey} options={valueOptions} size="small" />
        ) : def.valueType === 'select' ? (
          <Controller
            fullWidth
            control={control}
            name={formKey}
            as={
              <Select>
                {valueOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            }
          />
        ) : (
          <Controller
            fullWidth
            as={TextField}
            type="number"
            name={formKey}
            control={control}
            inputProps={inputProps}
          />
        )}
      </Box>
    </Box>
  );
};
export default FilterCriterion;
