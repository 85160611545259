import { TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IAlertFormResponse } from './createOrEditAlertForm';
import NanolikeCircleMap from 'shared/widgets/map/NanolikeCircleMap';

const AlertFormMap = () => {
  const [map, setMap] = useState<google.maps.Map>();
  const { t } = useTranslation();
  const form = useFormContext<IAlertFormResponse>();
  const { id } = useParams<{ id: string }>();
  const isNew = id === 'new' ? true : false;

  const onSearchedPlaces = (results: google.maps.places.PlaceResult[]) => {
    const geometry = results[0]?.geometry;
    if (!map || !geometry) return;

    const bounds = new google.maps.LatLngBounds();
    if (geometry.viewport) {
      // Only geocodes have viewport.
      bounds.union(geometry.viewport);
    } else {
      bounds.extend(geometry.location);
    }

    map.fitBounds(bounds);
  };

  return (
    <Box marginTop={2}>
      {/* Register form values, but hidden since we manage them manually  */}
      <input name="geofencing_lat" ref={form.register({ required: true })} type="hidden" />
      <input name="geofencing_lng" ref={form.register({ required: true })} type="hidden" />
      <input name="geofencing_radius" ref={form.register({ required: true })} type="hidden" />

      <Box marginBottom={2}>
        {form.errors && form.errors.geofencing_radius ? (
          <Alert variant="outlined" severity="error">
            {t('requiredGeofencing')}
          </Alert>
        ) : (
          <Alert variant="outlined" severity="info" color="info">
            {t('geofencing_desc')}
          </Alert>
        )}
      </Box>

      {/* Could be refactored to reuse code from LocationAutocomplete */}
      {map && (
        <TextField
          label={t('searchAddressPH')}
          fullWidth
          inputRef={inputRef => {
            // Bind the search input to Google Map autocomplete
            if (inputRef && window.google?.maps) {
              const searchBox = new window.google.maps.places.SearchBox(inputRef);
              searchBox.addListener('places_changed', () => {
                onSearchedPlaces(searchBox.getPlaces());
              });
            }
          }}
        />
      )}

      <NanolikeCircleMap
        onLoad={({ map }) => setMap(map)}
        circle={
          isNew
            ? null
            : {
                lat: Number(form.getValues().geofencing_lat),
                lng: Number(form.getValues().geofencing_lng),
                radius: Number(form.getValues().geofencing_radius)
              }
        }
        onCircleChanged={circle => {
          form.setValue('geofencing_lat', circle.lat);
          form.setValue('geofencing_lng', circle.lng);
          form.setValue('geofencing_radius', circle.radius);
        }}
        height="350px"
      />
    </Box>
  );
};

export default AlertFormMap;
