import React from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'config/store';
import { IProduct } from 'shared/model/product.model';
import { ILabelValueOption } from 'shared/utils/select-utils';
import { IGroup } from 'shared/model/group.model';
import {
  TextField,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme
} from '@material-ui/core';
import MultipleAutoComplete from 'shared/widgets/form/multipleAutocomplete';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { useProductAutoOrders } from 'shared/hooks/autoOrders';
import { normalizeMaterialNo } from 'shared/model/autoOrder.model';

export interface IProductFormResponse {
  device_content: string;
  device_content_reference: string;
  contract_number: string;
  groups: ILabelValueOption<IGroup['group_id']>[];
}

const ProductForm: React.FC<{
  product?: IProduct;
  onSubmit: (data: IProductFormResponse) => void;
  onCancel: () => void;
  onlyEditReferences?: boolean;
}> = ({ product, onSubmit, onCancel, onlyEditReferences = false }) => {
  const isNew = !product?.id;
  const { t } = useTranslation();
  const theme = useTheme();
  const updating = useSelector(({ product }: IRootState) => product.updating);
  const form = useForm<IProductFormResponse>({
    mode: 'onChange',
    defaultValues: {
      device_content: isNew ? '' : product?.device_content?.trim(),
      device_content_reference: isNew ? '' : normalizeMaterialNo(product?.device_content_reference),
      contract_number: isNew ? '' : product?.contract_number?.trim(),
      groups: isNew
        ? []
        : product?.groups?.map(group => ({ label: group.group_name, value: group.group_id })) ?? []
    }
  });
  // Access dirtyFields to subscribe to its changes.
  const dirtyFields = form.formState.dirtyFields;

  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const groups = useSelector(({ group }: IRootState) => group.groups);

  const groupOptions: ILabelValueOption<IGroup['group_id']>[] = groups.map(aGroup => ({
    label: aGroup.group_name,
    value: aGroup.group_id
  }));

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hasAutoOrder = product && useProductAutoOrders(product).length > 0;

  return (
    <FormContext {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle>{t(isNew ? 'create_product' : 'edit_product')}</DialogTitle>
        <DialogContent dividers>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={t('name')}
            fullWidth
            name="device_content"
            disabled={updating || onlyEditReferences}
            inputRef={form.register({
              required: <Trans i18nKey="required_field">Required Field</Trans>
            })}
            error={!!form.errors.device_content}
            helperText={
              form.errors.device_content ? (
                form.errors.device_content.message
              ) : hasAutoOrder && dirtyFields.has('device_content') ? (
                <span style={{ color: theme.palette.warning.main }}>
                  {t('warning_auto_orders_impacted')}
                </span>
              ) : null
            }
          />
          <TextField
            margin="dense"
            id="device_content_reference"
            label={t('reference')}
            fullWidth
            name="device_content_reference"
            disabled={updating}
            inputRef={form.register()}
            error={!!form.errors.device_content_reference}
            helperText={
              form.errors.device_content_reference ? (
                form.errors.device_content_reference.message
              ) : hasAutoOrder && dirtyFields.has('device_content_reference') ? (
                <span style={{ color: theme.palette.warning.main }}>
                  {t('warning_auto_orders_impacted')}
                </span>
              ) : null
            }
          />
          {settings.find(s => s.key === 'deviceContentWithContractNumber')?.value === 'true' && (
            <TextField
              margin="dense"
              id="contract_number"
              label={t('contract_number')}
              fullWidth
              name="contract_number"
              disabled={updating}
              inputRef={form.register()}
              error={!!form.errors.contract_number}
              helperText={
                form.errors.contract_number ? (
                  form.errors.contract_number.message
                ) : hasAutoOrder && dirtyFields.has('contract_number') ? (
                  <span style={{ color: theme.palette.warning.main }}>
                    {t('warning_auto_orders_impacted')}
                  </span>
                ) : null
              }
            />
          )}
          {settings.find(s => s.key === 'filterContentByGroup')?.value === 'true' && (
            <MultipleAutoComplete
              label={t('availability')}
              name="groups"
              options={groupOptions}
              disabled={updating || onlyEditReferences}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} startIcon={<CancelIcon />}>
            {t('cancel')}
          </Button>
          <Button type="submit" color="primary" startIcon={<SaveIcon />}>
            {t('save')}
          </Button>
        </DialogActions>
      </form>
    </FormContext>
  );
};

export default ProductForm;
