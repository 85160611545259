import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { IDevice } from 'shared/model/device.model';
import DeviceIcon from './deviceIcon';
import { Link as LinkIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: 'none',
      color: 'unset',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    icon: {
      width: '32px',
      height: '32px'
    },
    name: {
      '&>div:first-child': {
        overflow: 'initial !important',
        textOverflow: 'initial'
      },
      '&>div': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    }
  })
);

interface IDeviceName {
  device: IDevice;
}

const DeviceName = (props: IDeviceName) => {
  const classes = useStyles();

  const { device } = props;

  return (
    <Link to={`/devices/${device.device_id}`} className={classes.link}>
      <Box display="flex" alignItems="center" className={classes.name}>
        {(device.is_silo && device.is_combined) || // make silo list less cluttered
          (device.is_tank && (
            <Box>
              <DeviceIcon device={device} />
            </Box>
          ))}
        <Box p={1}>
          <Box fontWeight="fontWeightBold" display="flex" alignItems="center" flexDirection="row">
            {device.is_combined && (
              <Box pr={0.5}>
                <LinkIcon fontSize="small" />
              </Box>
            )}
            <span>{device.device_name}</span>
          </Box>
          <Box fontWeight="fontWeightLight">{device.device_reference}</Box>
        </Box>
      </Box>
    </Link>
  );
};

export default DeviceName;
