import { Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import axios from 'axios';
import { getEnvApiUrl } from 'config/env';
import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { login } from 'shared/reducers/authenticationSlice';
import { errorNotification } from 'shared/reducers/notifierSlice';
import { getCurrentTimeZone, getTimezonesNames } from 'shared/utils/date-utils';
import { getLangOptions } from 'shared/utils/lang-utils';
import { ILabelValueOption } from 'shared/utils/select-utils';
import { validPasswordStrength } from 'shared/utils/user-utils';
import PasswordTextField from 'shared/widgets/form/passwordTextField';
import SingleAutoComplete from 'shared/widgets/form/singleAutocomplete';

const apiUrl = getEnvApiUrl();

interface IFormResponse {
  firstname: string;
  lastname: string;
  email: string;
  preferred_language: ILabelValueOption;
  preferred_timezone: ILabelValueOption;
  password: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      width: '20rem'
    },
    avatar: {
      backgroundColor: theme.palette.error.main,
      margin: theme.spacing(1),
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    resetPasswordBtn: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    resetPasswordTitle: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    }
  })
);

interface IRegisterCardProps {
  email: string;
  lang: string;
  first_name: string | null;
  last_name: string | null;
}

const RegisterCard = (props: IRegisterCardProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [signUp, setSignUp] = useState<boolean>(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const history = useHistory();
  const dispatch = useDispatch();

  const langOptions = getLangOptions();

  const langDefault = langOptions.find(option => props.lang.includes(option.value));
  const langDefaultOption = langDefault ? langDefault : langOptions[0];

  const form = useForm<IFormResponse>({
    mode: 'onChange',
    defaultValues: {
      firstname: props?.first_name ?? '',
      lastname: props?.last_name ?? '',
      password: '',
      email: props.email,
      preferred_language: langDefaultOption,
      preferred_timezone: getCurrentTimeZone()
    }
  });

  const trySignUp = async (responses: IFormResponse) => {
    const body: any = { ...responses };
    body.preferred_language = body.preferred_language.value;
    body.preferred_timezone = responses.preferred_timezone.value;

    try {
      setSignUp(true);
      await axios.post(`${apiUrl}/v1/register?token=${token}`, body);
      setSignUp(false);
      dispatch(
        login({
          username: responses.email,
          password: responses.password
        })
      );
      history.push('/');
    } catch (e) {
      setSignUp(false);
      dispatch(errorNotification(t('error')));
    }
  };

  const onSubmit = form.handleSubmit((responses: IFormResponse) => {
    trySignUp(responses);
  });

  return (
    <FormContext {...form}>
      <form className={classes.root} noValidate autoComplete="off" onSubmit={onSubmit}>
        <Card>
          <CardContent>
            <Avatar className={classes.avatar}>
              <AccountCircleIcon />
            </Avatar>
            <Box>
              <Typography>{t('create_account')}</Typography>
            </Box>
            {/*loginError && <Alert severity="error">{t('bad_credentials')}</Alert>*/}
            <TextField
              margin="dense"
              required
              fullWidth
              label={t('given_name')}
              defaultValue={props.first_name}
              name="firstname"
              inputRef={form.register({ required: true })}
              disabled={signUp}
              autoFocus
            />
            <TextField
              margin="dense"
              required
              fullWidth
              id="last_name"
              label={t('family_name')}
              defaultValue={props.last_name}
              name="lastname"
              inputRef={form.register({ required: true })}
              disabled={signUp}
            />
            <TextField
              margin="dense"
              required
              fullWidth
              label={t('email_address')}
              defaultValue={props.email}
              name="email"
              disabled={true}
              inputRef={form.register({ required: true })}
            />
            <PasswordTextField
              margin="dense"
              required
              fullWidth
              label={t('password')}
              name="password"
              autoComplete="new-password"
              disabled={signUp}
              inputRef={form.register({
                validate: value => {
                  if (!value) {
                    return <Trans i18nKey="required_field">Required Field</Trans>;
                  }
                  if (!validPasswordStrength(value)) {
                    return <Trans i18nKey="password_check">password_check</Trans>;
                  }
                  return true;
                }
              })}
              error={form.errors.password ? true : false}
              helperText={form.errors.password && form.errors.password.message}
            />
            <SingleAutoComplete
              disableClearable
              name="preferred_language"
              label={t('lang')}
              defaultValue={langDefaultOption}
              options={langOptions}
              disabled={signUp}
            />
            <SingleAutoComplete
              disableClearable
              name="preferred_timezone"
              label={t('timezone')}
              defaultValue={getCurrentTimeZone()}
              options={getTimezonesNames()}
              disabled={signUp}
            />
          </CardContent>
          <CardActions>
            <Button color="primary" variant="contained" type="submit" fullWidth disabled={signUp}>
              {t('sign_up')}
            </Button>
          </CardActions>
        </Card>
      </form>
    </FormContext>
  );
};

export default RegisterCard;
