import { Box, Button, createStyles, makeStyles, Paper, Popover, Theme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { IRootState } from 'config/store';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import React, { useEffect, useMemo } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IFilterCriterionValue, IGraph } from 'shared/model/graph.model';
import { updateGraphFilter } from 'shared/reducers/graphsSlice';
import { useWorkspaceType } from 'shared/utils/workspace-utils';
import {
  getFilterKeyFromFormKey,
  getFilterOperatorFormKey,
  getAvailableFilters,
  getFilterValueFormKey
} from './filterGraph.model';
import FilterCriterion from './FilterCriterion';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activeFilter: {
      color: theme.palette.success.main
    }
  })
);

export interface INanoDashboardGridItemFilterProps {
  graph: IGraph;
}

const NanoDashboardGridItemFilter = (props: INanoDashboardGridItemFilterProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const workspaceType = useWorkspaceType(settings);

  const { graph } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const savedFilters = useMemo(
    () => (graph.client_filter ? graph.client_filter : []),
    [graph.client_filter]
  );
  const isActive = savedFilters.length > 0;

  const availableFilters = useMemo(
    () => getAvailableFilters(graph.type, workspaceType),
    [graph.type, workspaceType]
  );

  const defaultValues = useMemo(() => {
    return availableFilters.reduce((acc, filter) => {
      return {
        ...acc,
        [getFilterOperatorFormKey(filter.key)]: filter.operators[0] ?? '=',
        [getFilterValueFormKey(filter.key)]: filter.defaultValue
      };
    }, {});
  }, [availableFilters]);

  const initialValues = useMemo(() => {
    const initialValues: any = { ...defaultValues };

    savedFilters.forEach(savedFilter => {
      let value: any;
      if (isArray(savedFilter.value)) {
        value = savedFilter.value.map(item => ({
          label: item,
          value: item
        }));
      } else {
        value = savedFilter.value;
      }

      initialValues[getFilterOperatorFormKey(savedFilter.key)] = savedFilter.operator;
      initialValues[getFilterValueFormKey(savedFilter.key)] = value;
    });

    return initialValues;
  }, [defaultValues, savedFilters]);

  const form = useForm({
    defaultValues: initialValues
  });

  useEffect(() => {
    form.reset(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const resetAll = () => {
    form.reset(defaultValues);
    const newGraph: Partial<IGraph> = {
      graph_id: graph.graph_id,
      client_filter: []
    };

    dispatch(updateGraphFilter(newGraph));
  };

  const onSubmit = (responses: any) => {
    // Example responses:
    // {
    //   "missingWeight___operator": "<=",
    //   "missingWeight___value": "4",
    //   "device_content_id___operator": "in",
    //   "device_content_id___value": [
    //     {
    //       "label": "LM II/A-S52,5 N PM",
    //       "value": "LM II/A-S52,5 N PM"
    //     },
    //     {
    //       "label": "LC LAFARGE CRETOMAX 300",
    //       "value": "LC LAFARGE CRETOMAX 300"
    //     }
    //   ]
    // }

    // e.g. level_percent, device_content_id, ...
    const filterKeys = Array.from(new Set(Object.keys(responses).map(getFilterKeyFromFormKey)));

    const filters = filterKeys
      .map(key => {
        const operator = responses[getFilterOperatorFormKey(key)];

        let value = responses[getFilterValueFormKey(key)];
        if (isArray(value)) {
          value = value.map(item => item.value);
        } else if (['=', '>=', '<='].includes(operator)) {
          value = typeof value === 'number' || value.length ? Number(value) : null;
        }

        return {
          key,
          operator,
          value
        } as IFilterCriterionValue;
      })
      // Remove empty values
      .filter(({ key, operator, value }) => {
        if (isArray(value) || isString(value)) {
          return value.length > 0;
        } else {
          return value !== null;
        }
      });

    const newGraph: Partial<IGraph> = {
      graph_id: graph.graph_id,
      client_filter: filters
    };

    dispatch(updateGraphFilter(newGraph));

    setIsOpen(false);
  };

  if (availableFilters.length === 0) {
    return null;
  }

  return (
    <>
      <Button
        color="primary"
        size="small"
        variant="text"
        onClick={event => {
          setAnchorEl(event.currentTarget);
          setIsOpen(true);
        }}
        className={isActive ? classes.activeFilter : undefined}
      >
        {isActive && <CheckIcon />}
        {t('filter_graph')}
      </Button>
      <Popover
        id={`graph_filter_${graph.graph_id}`}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Paper elevation={3}>
          <Box padding={2}>
            <FormContext {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <Box>{t('filter_graph_title')}</Box>
                <Box>
                  {availableFilters.map(filterDef => (
                    <FilterCriterion key={filterDef.key} def={filterDef} graph={graph} />
                  ))}
                </Box>
                <Box textAlign="right">
                  <Button type="button" variant="text" onClick={resetAll}>
                    {t('reset')}
                  </Button>
                  <Button type="submit" variant="text">
                    {t('apply')}
                  </Button>
                </Box>
              </form>
            </FormContext>
          </Box>
        </Paper>
      </Popover>
    </>
  );
};

export default NanoDashboardGridItemFilter;
