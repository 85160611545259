import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import 'rc-slider/assets/index.css';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import greenAtlasMarker from 'shared/icons/maps/green_atlas_marker.png';
import greenWifiMarker from 'shared/icons/maps/green_wifi_marker.png';
import defaultWifiMarker from 'shared/icons/maps/default_wifi_marker.png';
import { levelThresholds } from 'shared/widgets/visualization/graphLevel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      backgroundColor: theme.palette.common.white
    },
    btn: {
      maxWidth: '40px',
      maxHeight: '40px',
      minWidth: '40px',
      minHeight: '40px',
      marginLeft: '10px',
      marginRight: '10px',
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.common.white
      }
    },
    card: {
      '&>*': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1) + 'px !important'
      }
    }
  })
);

// @ts-ignore
const MapDeviceLevelLegend = ({ isIbc }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <Box display="flex" alignItems="top">
      {open && (
        <Card className={classes.card}>
          <CardContent>
            <Box textAlign="center" marginBottom={0.5} fontSize="1rem" fontWeight="bold">
              {t('legendTitle')}
            </Box>
            <Box textAlign="center" marginBottom={1} fontSize="0.8rem">
              {t('mapLegend')}
            </Box>
            <Box
              height="20px"
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              className={classes.background}
            >
              {[
                {
                  title: `< ${levelThresholds[0]} %`,
                  style: { backgroundColor: theme.palette.error.main }
                },
                {
                  title: '',
                  style: { backgroundColor: theme.palette.warning.main }
                },
                {
                  title: `> ${levelThresholds[1]} %`,
                  style: { backgroundColor: theme.palette.success.main }
                }
              ].map(item => (
                <Box
                  key={item.title}
                  height="20px"
                  width="80px"
                  style={item.style}
                  title={item.title}
                  fontSize="0.8rem"
                  paddingTop="2px"
                  textAlign="center"
                  color="white"
                  fontWeight="bold"
                >
                  {item.title}
                </Box>
              ))}
            </Box>
            {isIbc && (
              <>
                <Box
                  textAlign="center"
                  marginBottom={0.5}
                  fontSize="1rem"
                  fontWeight="bold"
                  marginTop={1.5}
                >
                  {t('legendGeoloc')}
                </Box>
                <Box display="flex" justifyContent="space-around">
                  <Box title={t('defaultDeviceIcon')}>
                    <Box textAlign="center">
                      <img src={greenAtlasMarker} alt={t('defaultDeviceIcon')} height="40px" />
                    </Box>
                    <Box fontSize="0.8rem" textAlign="center">
                      {t('defaultDeviceIcon')}
                    </Box>
                  </Box>
                  <Box title="Wifi">
                    <Box textAlign="center">
                      <img src={greenWifiMarker} alt="Wifi" height="40px" />
                    </Box>
                    <Box fontSize="0.8rem" textAlign="center">
                      Wifi
                    </Box>
                  </Box>
                </Box>
              </>
            )}
            {isIbc && (
              <>
                <Box
                  textAlign="center"
                  marginBottom={0}
                  fontSize="1rem"
                  fontWeight="bold"
                  marginTop={1.5}
                >
                  {t('devices_status.status')}
                </Box>
                <Box display="flex" justifyContent="space-around">
                  <Box>
                    <Box textAlign="center">
                      <img
                        src={defaultWifiMarker}
                        alt={t('devices_status.level_problem.label')}
                        height="40px"
                      />
                    </Box>
                    <Box fontSize="0.8rem" textAlign="center">
                      {t('devices_status.level_problem.label')}
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </CardContent>
        </Card>
      )}
      <Button variant="outlined" size="small" onClick={toggle} className={classes.btn}>
        <InfoIcon style={{ color: 'grey' }} />
      </Button>
    </Box>
  );
};
export default MapDeviceLevelLegend;
