import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl } from 'config/env';
import { IRootState } from 'config/store';
import Highcharts, { SeriesOptionsType, YAxisOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Boost from 'highcharts/modules/boost';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import ExportData from 'highcharts/modules/export-data';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import sizeMe, { SizeMeProps } from 'react-sizeme';
import { getHighchartsLang } from 'shared/i18n/highChartsUtils';
import { IGetGraphDataResponse } from 'shared/model/api.model';
import { IGraph } from 'shared/model/graph.model';
import { IMe } from 'shared/model/user.model';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import { fasterTimestampToUnixtimestamp, offsetStringToTime } from 'shared/utils/date-utils';
import Loading from 'shared/widgets/loading';
import useInterval from 'shared/hooks/useInterval';
import { useLocalizedUnit, useLocalizedDataTypeWithUnit } from 'shared/utils/lang-utils';

Exporting(Highcharts);
Boost(Highcharts);
ExportData(Highcharts);
OfflineExporting(Highcharts);
NoDataToDisplay(Highcharts);

const FORECAST_REGEX = /(\w*)JPlus\d(\w*)/;

const defaultOptions: Highcharts.Options = {
  title: {
    text: ''
  },
  xAxis: {
    type: 'datetime',
    title: {
      text: null
    },
    tickWidth: 0,
    lineWidth: 0,
    crosshair: {
      color: '#999'
    },
    gridLineWidth: 0,
    minPadding: 0.05,
    maxPadding: 0.05,
    labels: {
      enabled: true
    }
  },
  chart: {
    ignoreHiddenSeries: true,
    styledMode: false,
    type: 'line',
    zoomType: 'x',
    animation: false,
    reflow: true
  },
  boost: {
    allowForce: true,
    enabled: true,
    useGPUTranslations: true,
    debug: {
      showSkipSummary: true,
      timeBufferCopy: true,
      timeKDTree: true,
      timeRendering: true,
      timeSeriesProcessing: true,
      timeSetup: true
    }
  },
  plotOptions: {
    line: {
      animation: false,
      shadow: false,
      cropThreshold: Infinity,
      boostThreshold: 1000000,
      turboThreshold: Number.MAX_VALUE
    },
    series: {
      showInNavigator: true,
      animation: false,
      allowPointSelect: false,
      boostThreshold: 1,
      marker: {
        enabled: false,
        // symbol: 'circle',
        enabledThreshold: 10,
        states: {
          hover: {
            lineWidth: 1,
            radius: 5
          }
        },
        lineWidth: 1
      }
    }
  },
  credits: {
    enabled: false
  },
  tooltip: {
    enabled: true,
    shared: true,
    backgroundColor: '#f0f0f0',
    followPointer: true,
    followTouchMove: true,
    borderRadius: 3,
    borderWidth: 1,
    footerFormat: '',
    headerFormat: '<div><span style="font-size: 12px">{point.key}</span><div>',
    hideDelay: 500,
    padding: 8,
    xDateFormat: '%d/%m/%Y - %H:%M:%S',
    pointFormat:
      '<div><span style="color:{point.color}">\u25CF</span> {series.name} : <b>{point.y}{series.options.custom.unit}</b></div>',
    shadow: true,
    shape: 'callout',
    snap: 10,
    split: false,
    useHTML: true,
    valuePrefix: ''
  },
  exporting: {
    // fallbackToExportServer: false,
    enabled: true
  },
  series: []
};

const apiUrl = getEnvApiUrl();

const containerProps = { style: { height: '100%' } };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 'calc(100% - 10px)'
    }
  })
);

interface INanoCurveConfigState {
  loading: boolean;
  loadSuccess: boolean;
  errorMessage: string;
  options: Highcharts.Options;
}

const initialState: INanoCurveConfigState = {
  loading: false,
  loadSuccess: false,
  errorMessage: '',
  options: defaultOptions
};

interface INanoCurveVizProps extends SizeMeProps {
  graph: IGraph;
}

const NanoCurveViz = (props: INanoCurveVizProps) => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement | null>(null);
  const me = useSelector(({ authentication }: IRootState) => authentication.me) as IMe;
  const groups = useSelector(({ group }: IRootState) => group.groups);
  const [state, setState] = useState<INanoCurveConfigState>(initialState);
  const { t } = useTranslation();
  const { localizedUnit } = useLocalizedUnit();
  const { localizedDataTypeWithUnit } = useLocalizedDataTypeWithUnit();
  const { graph, size } = props;
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      // @ts-ignore
      chartRef.current.chart.setSize(size.width, size.height);
    }
  }, [size.height, size.width]);

  const loadGraphData = useCallback(async () => {
    let ids = [...graph.device_ids];
    graph.group_ids.forEach((aGroupId: string) => {
      const group = groups.find(aGroup => aGroup.group_id === aGroupId);
      if (group && group.devices) {
        const deviceIds = group.devices.map(aDevice => aDevice.device_id);
        ids = ids.concat(deviceIds);
      }
    });

    try {
      setState({
        ...initialState,
        loading: true
      });
      const momentDuration = graph.start_offset_time
        ? moment.duration(offsetStringToTime(graph.start_offset_time) * 1000)
        : 0;

      const body = {
        data_types: graph.device_data_types,
        device_ids: ids,
        is_last_value: graph.is_last_value,
        from_timestamp: moment().subtract(momentDuration).format()
      };

      const response: AxiosResponse<IGetGraphDataResponse> = await axios.post(
        `${apiUrl}/v1/get-graph-data`,
        body
      );
      const graphDatas = response.data.data;

      const yAxis: Array<YAxisOptions> = [];
      const series = graphDatas
        .filter(
          graphData =>
            !graphData.data_type.match(FORECAST_REGEX) &&
            ['ok', 'problem'].includes(graphData.status)
        )
        .map(aGraphData => {
          const data = aGraphData.data_points
            .filter(dataPoint => dataPoint.value !== null)
            .map(dataPoint => [
              fasterTimestampToUnixtimestamp(dataPoint.timestamp),
              aGraphData.is_silo && aGraphData.data_type === 'level_kg'
                ? (dataPoint.value as number) / 1000
                : (dataPoint.value as number)
            ]);
          const unit = aGraphData.data_points[0]?.unit
            ? ` (${localizedUnit(aGraphData.data_points[0]?.unit, 'long')})`
            : '';
          const text = localizedDataTypeWithUnit(aGraphData.data_type);
          if (!yAxis.some(axe => axe.id === aGraphData.data_type)) {
            const format = '{value}';
            yAxis.push({
              id: aGraphData.data_type,
              title: {
                text: text
              },
              labels: {
                format
              },
              min: 0
            });
          }

          const name = aGraphData.farm_name
            ? `${aGraphData.device_name} - ${aGraphData.farm_name}`
            : aGraphData.device_name;
          const serie: SeriesOptionsType = {
            type: 'line',
            custom: {
              unit
            },
            name: name,
            yAxis: aGraphData.data_type,
            data,
            zoneAxis: 'x',
            zones: [
              {
                value: new Date().getTime()
              },
              {
                dashStyle: 'Dot'
              }
            ]
          };
          return serie;
        });

      //forecastData
      const forecast = graphDatas.filter(graphData => graphData.data_type.match(FORECAST_REGEX));
      forecast.sort((f1, f2) => {
        const d1 = f1.data_type;
        const d2 = f2.data_type;
        return d1.localeCompare(d2);
      });

      forecast.forEach(aGraphData => {
        const match = aGraphData.data_type.match(FORECAST_REGEX) as RegExpMatchArray;
        const dataType = match[2] ? `${match[1]}_${match[2]}`.toLowerCase() : match[1];
        const serie = series.find(
          aSerie => aSerie.name === aGraphData.device_name && aSerie.yAxis === dataType
        );
        if (serie?.data) {
          const data = serie.data;
          aGraphData.data_points.forEach(dataPoint => {
            data.push([
              fasterTimestampToUnixtimestamp(dataPoint.timestamp),
              dataPoint.value as number
            ]);
          });
        }
      });

      const options = {
        ...cloneDeep(defaultOptions),
        series,
        yAxis,
        lang: getHighchartsLang(t),
        noData: {
          style: {
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#303030'
          }
        }
      };

      // if there is no forecast force the x axis max value to now.
      if (forecast.length === 0 && options.xAxis) {
        const xAxis = options.xAxis as Highcharts.XAxisOptions;
        xAxis.max = new Date().getTime();
      }

      const state = {
        ...initialState,
        loading: false,
        loadSuccess: true,
        errorMessage: '',
        options
      };

      setState(state);
    } catch (e) {
      setState({
        ...initialState,
        errorMessage: getRequestErrorMessage(e)
      });
    }
  }, [graph, groups, t, localizedUnit, localizedDataTypeWithUnit]);

  useInterval(loadGraphData);

  return (
    <div ref={ref} className={classes.root}>
      {state.loading ? (
        <Loading />
      ) : (
        <>
          {state.options.series && state.options.series.length > 0 ? (
            <HighchartsReact
              ref={chartRef}
              highcharts={Highcharts}
              options={{
                ...state.options,
                time: { timezone: me.configuration.preferred_timezone, moment: moment }
              }}
              immutable={false}
              containerProps={containerProps}
            />
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Box>{t('no_data_available')}</Box>
            </Box>
          )}
        </>
      )}
    </div>
  );
};

const sizeMeConfig = {
  monitorWidth: true,
  monitorHeight: true,
  refreshRate: 100,
  refreshMode: 'debounce',
  noPlaceholder: true
};
// @ts-ignore
export default sizeMe(sizeMeConfig)(NanoCurveViz);
