import MarkerClusterer from '@google/markerclustererplus';
import Box from '@material-ui/core/Box';
import config from 'config/config';
import { IRootState } from 'config/store';
import Typography from '@material-ui/core/Typography';

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IDataPointPositionValue, IGraphData, nanolikeDataType } from 'shared/model/api.model';
import { workspaceIsIbc } from 'shared/utils/workspace-utils';
import MapMarker, { getDeviceIcon } from 'shared/widgets/map/mapMarker';
import NanolikeMap from 'shared/widgets/map/nanolikeMap';
import { getDeviceDataType, getMarkerContent, onMarkerClusterClick } from './map/map-utils';
import { useMapControl } from './map/mapControl';
import MapDaySlider from './map/mapDaySlider';
import MapDeviceLevelLegend from './map/mapLegend';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import MapErrorMessage from './map/mapErrorMessage';
export interface IDDeviceMapProps {
  data?: IGraphData[];
  height?: string;
  onLoadForecast?: (day: number) => void;
  errorOnLoad?: string;
}

let maps: any;

const DeviceMap = (props: IDDeviceMapProps) => {
  const clusteredInfoWindowRef = useRef<google.maps.InfoWindow>();
  const clusterersRef = useRef<MarkerClusterer[]>([]);
  const initialized = useRef(false);

  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isIbc = workspaceIsIbc(settings);

  const { t } = useTranslation();
  const { data } = props;

  const [selection, setSelection] = useState<google.maps.Marker[]>([]);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [day, setDay] = useState<number>(0);
  const [noDataToDisplay, setnoDataToDisplay] = useState(false);

  const onDayChange = (newDay: number) => {
    closeClusteredWindow();
    setSelection([]);
    if (props?.onLoadForecast) {
      props.onLoadForecast(newDay);
    }
    setDay(newDay);
  };

  useMapControl(map, 2, <MapErrorMessage isError={!!(isIbc && props.errorOnLoad)} />);
  useMapControl(
    map,
    11,
    <MapDaySlider defaultValue={day} onDayChange={onDayChange} isIbc={isIbc} />
  );
  useMapControl(map, 7, <MapDeviceLevelLegend isIbc={isIbc} />);

  const closeClusteredWindow = () => {
    if (clusteredInfoWindowRef.current) {
      clusteredInfoWindowRef.current.close();
    }
  };

  const onLoad = (e: any) => {
    const gMap = e.map;
    maps = e.maps;
    setMap(gMap);
  };

  useEffect(() => {
    const clearMarkers = () => {
      clusterersRef.current.forEach(cluster => {
        cluster.clearMarkers();
        cluster.setMap(null);
      });
      clusterersRef.current = [];
      closeClusteredWindow();
    };

    clearMarkers();
    if (map && maps && data && data.length > 0) {
      const positionData = data.filter(aData => aData.data_type === 'position');

      const levelDayPercent =
        day > 0 ? (`levelJPlus${day}Percent` as nanolikeDataType) : 'level_percent';

      const bounds = new google.maps.LatLngBounds();
      const markers: google.maps.Marker[] = [];
      //@ts-ignore
      if (
        !positionData.filter(
          d => d.data_points[0].value.position_latitude && d.data_points[0].value.position_longitude
        ).length
      ) {
        setnoDataToDisplay(true);
        return () => {
          clearMarkers();
        };
      }
      positionData.forEach(aData => {
        const locationValue = aData.data_points[0].value as IDataPointPositionValue;
        const radius = locationValue.position_radius ? locationValue.position_radius : 60;

        const markerDatas = data.filter(markerData => markerData.device_id === aData.device_id);
        const content = getMarkerContent(markerDatas, day, t);
        const deviceLevelData = getDeviceDataType(data, levelDayPercent, aData.device_id);
        const level = deviceLevelData ? deviceLevelData.data_points[0].value : undefined;

        // if (locationValue.position_latitude && locationValue.position_longitude){
        const center =
          locationValue.position_latitude && locationValue.position_longitude
            ? {
                lat: locationValue.position_latitude,
                lng: locationValue.position_longitude
              }
            : config.map.defaultCenter;

        const icon = getDeviceIcon(
          aData.status,
          level as number,
          locationValue.position_radius === 0,
          aData.is_silo,
          aData.is_tank
        );
        const marker = new maps.Marker({
          id: aData.device_id,
          position: center,
          title: aData.device_name,
          content,
          icon
        });
        marker.addListener('click', (e: any) => {
          closeClusteredWindow();
          setSelection([marker]);
        });

        markers.push(marker);
        const circle: google.maps.Circle = new maps.Circle({
          strokeColor: '#D2EBE7',
          strokeOpacity: 0.9,
          strokeWeight: 2,
          fillColor: '#D2EBE7',
          fillOpacity: 0.3,
          center,
          radius
        });
        bounds.union(circle.getBounds());
        // }
      });

      const clusterer = new MarkerClusterer(map, markers, {
        gridSize: 60,
        averageCenter: true,
        enableRetinaIcons: true,
        zoomOnClick: false
      });
      clusterer.setStyles(
        clusterer.getStyles().map(style => {
          style.url = `data:image/svg+xml;base64,${clusterIconBase64}`;
          style.textColor = 'white';
          return style;
        })
      );
      clusterer.addListener('click', (e: any) => {
        setSelection([]);
        closeClusteredWindow();
        clusteredInfoWindowRef.current = onMarkerClusterClick(t, e, data, day);
      });
      clusterersRef.current.push(clusterer);

      if (bounds && !initialized.current) {
        setTimeout(() => {
          // fix cluster pb
          map.fitBounds(bounds);
        }, 50);
        initialized.current = true;
      }
    }
    return () => {
      clearMarkers();
    };
  }, [day, map, data, t]);

  const onMapMarkerClose = () => setSelection([]);

  const positionData = data ? data.filter(aData => aData.data_type === 'position') : [];
  return noDataToDisplay ? (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'inherit'
      }}
    >
      <SentimentVeryDissatisfiedIcon fontSize="large" color="primary" />
      <Typography variant="h6">{t('unavailable-data')}</Typography>
    </Box>
  ) : (
    <NanolikeMap data={positionData} onLoad={onLoad} height={props.height}>
      <MapMarker map={map} markers={selection} onClose={onMapMarkerClose}></MapMarker>
    </NanolikeMap>
  );
};

const clusterIconBase64 = (() => {
  const radius = 120;
  const radiusStep = 30;
  return window.btoa(`
  <svg fill="#2E48A7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    <circle cx="120" cy="120" opacity=".8" r="${radius - 2 * radiusStep}" />
    <circle cx="120" cy="120" opacity=".4" r="${radius - 1 * radiusStep}" />
    <circle cx="120" cy="120" opacity=".1" r="${radius - 0 * radiusStep}" />
  </svg>`);
})();

export default DeviceMap;
