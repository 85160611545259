import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Warning } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusFilter: {
      height: '29px',
      minWidth: 100,
      maxWidth: 100,
      marginBottom: theme.spacing(1),
      marginTop: 0
    },
    empty: {
      color: 'rgba(0, 0, 0, 0.5)'
    }
  })
);

interface IStatusFilterProps {
  status: string;
  onChange: (event: React.ChangeEvent<any>) => void;
}

const StatusFilter = (props: IStatusFilterProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { status, onChange } = props;
  return (
    <Select
      labelId="device-battery-status-select-filter"
      value={status}
      onChange={onChange}
      className={classes.statusFilter}
    >
      <MenuItem value="null" className={classes.empty}>
        {t('rules_status')}
      </MenuItem>

      <MenuItem value="OK">
        <Typography variant="body2">{t('devices_status.ok.label')}</Typography>
      </MenuItem>

      <MenuItem value="KO">
        <Warning color="error" />
      </MenuItem>
    </Select>
  );
};

export default StatusFilter;
