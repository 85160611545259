import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl } from 'config/env';
import { AppThunk } from 'config/store';
import { IDevice } from 'shared/model/device.model';
import { IGroup } from 'shared/model/group.model';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import { errorNotification } from './notifierSlice';
import { findIndex } from 'lodash';
import { updateDevicesFromGroup } from './devicesSlice';

const initialState = {
  loading: false,
  errorMessage: '',
  groups: [] as IGroup[],
  group: null as IGroup | null,
  updating: false,
  updateSuccess: false
};

export type GroupState = typeof initialState;

export const slice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    updateGroupsWithDevicesReducer: (state, { payload: devices }: PayloadAction<IDevice[]>) => {
      if (devices.length) {
        state.groups.forEach(group => {
          group.devices = [];
          devices.forEach(device => {
            if (device.groupMembership?.find(item => item.group_id === group.group_id)) {
              // @ts-ignore
              group.devices.push(device);
            }
          });
          group.devices.sort((a, b) => a.device_name.localeCompare(b.device_name));
        });
      }
    },
    fetchGroupsStart: state => {
      state.loading = true;
      state.errorMessage = '';
    },
    fetchGroupsFailed: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    fetchGroupsSuccess: (state, action: PayloadAction<IGroup[]>) => {
      state.loading = false;
      state.groups = action.payload;
    },

    updateGroupStart: state => {
      state.updating = true;
      state.errorMessage = '';
      state.updateSuccess = false;
    },
    updateGroupFailed: (state, action: PayloadAction<string>) => {
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.payload;
    },
    updateGroupSuccess: (state, action: PayloadAction<IGroup>) => {
      const index = findIndex(state.groups, { group_id: action.payload.group_id });
      if (index !== -1) {
        action.payload.devices?.sort((a, b) => a.device_name.localeCompare(b.device_name));
        state.groups[index] = action.payload;
      } else {
        state.groups.push(action.payload);
      }
      state.updating = false;
      state.updateSuccess = true;
      state.group = action.payload;
    },
    deleteGroupSuccess: state => {
      state.updateSuccess = true;
      state.updating = false;
      state.group = null;
    },
    resetGroup: state => {
      state.group = null;
    }
  }
});

export default slice.reducer;

//Actions
const {
  fetchGroupsStart,
  fetchGroupsFailed,
  fetchGroupsSuccess,
  updateGroupStart,
  updateGroupFailed,
  updateGroupSuccess,
  deleteGroupSuccess,
  updateGroupsWithDevicesReducer
} = slice.actions;

export const { resetGroup } = slice.actions;

const apiUrl = getEnvApiUrl();

export const updateGroupsWithDevices = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  dispatch(updateGroupsWithDevicesReducer(state.devices.devices));
};
export const fetchGroups = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchGroupsStart());
    const response: AxiosResponse<IGroup[]> = await axios.get(
      `${apiUrl}/v1/groups/?withRelated=userGroupMemberships&ordering=group_name`
    );
    await dispatch(fetchGroupsSuccess(response.data));
    await dispatch(updateGroupsWithDevices());
  } catch (error) {
    const errorMsg = getRequestErrorMessage(error);
    dispatch(fetchGroupsFailed(errorMsg));
    dispatch(errorNotification(`${errorMsg}`));
  }
};

export const updateGroup =
  (group: any): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateGroupStart());

      //@ts-ignore
      const device_ids = group.devices.map(item => item.device_id);
      delete group.devices;
      delete group.accessories;
      const response: AxiosResponse<IGroup> = await axios.patch(
        `${apiUrl}/v1/groups/${group.group_id}/`,
        {
          ...group,
          device_ids
        }
      );

      dispatch(updateGroupSuccess(response.data));
      dispatch(updateDevicesFromGroup(response.data.group_id, response.data.devices));
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateGroupFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const createGroup =
  (group: any): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateGroupStart());
      //@ts-ignore
      const device_ids = group.devices.map(item => item.device_id);
      group.device_ids = device_ids;
      delete group.devices;
      const response: AxiosResponse<IGroup> = await axios.post(`${apiUrl}/v1/groups/`, group);
      const updatedGroup = response.data;
      dispatch(updateGroupSuccess(updatedGroup));
      dispatch(updateDevicesFromGroup(response.data.group_id, response.data.devices));
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateGroupFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const deleteGroup =
  (group: IGroup): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateGroupStart());
      await axios.delete(`${apiUrl}/v1/groups/${group.group_id}/`);
      dispatch(deleteGroupSuccess());
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateGroupFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };
