import {
  Box,
  Typography,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Divider,
  Paper
} from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDelete from 'shared/widgets/confirmDelete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { AutoOrderFormResponse } from './AutoOrderForm';
import { IDevice } from 'shared/model/device.model';
import { useFormContext } from 'react-hook-form';
import useAutoOrderTriggerDialog from './useAutoOrderTriggerDialog';
import { Alert } from '@material-ui/lab';
import { IGroup } from 'shared/model/group.model';
import { DeviceAutoOrder, sortDeviceAutoOrders } from 'shared/model/autoOrder.model';
import AutoOrderDeviceInfos from './AutoOrderDeviceInfos';
import isTruthy from 'shared/utils/isTruthy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ruleButtons: {
      '&>*': {
        marginLeft: theme.spacing(1)
      }
    }
  })
);

/**
 * Shows the device informations and all the existing trigger rules for it
 */
const AutoOrderDeviceConfiguration = ({
  group,
  device,
  editDeviceAutoOrder
}: {
  group: IGroup;
  device: IDevice;
  editDeviceAutoOrder?: DeviceAutoOrder;
}) => {
  const { t } = useTranslation();
  const form = useFormContext<AutoOrderFormResponse>();
  const autoOrders = form.watch('autoOrders') ?? {};
  const useAutoSettings = form.watch('useAutoSettings');
  const deviceAutoOrders = autoOrders[device.device_id] ?? [];
  const editable = !useAutoSettings;
  const lines = [
    ...sortDeviceAutoOrders(deviceAutoOrders, device.capa_max).map(autoOrder => (
      <DeviceAutoOrderRule
        key={autoOrder.alert_id}
        device={device}
        editable={editable}
        autoOrder={autoOrder}
        onUpdate={updatedAutoOrder => {
          form.setValue('autoOrders', {
            ...autoOrders,
            [device.device_id]: deviceAutoOrders.map(a =>
              a.alert_id === autoOrder.alert_id ? updatedAutoOrder : a
            )
          });
        }}
        onDelete={() => {
          form.setValue('autoOrders', {
            ...autoOrders,
            [device.device_id]: deviceAutoOrders.filter(
              ({ alert_id }) => autoOrder.alert_id !== alert_id
            )
          });
        }}
      />
    )),
    editable && (
      <DeviceAddAutoOrderRule
        key="add"
        device={device}
        deviceAutoOrders={deviceAutoOrders}
        onCreate={autoOrder => {
          form.setValue('autoOrders', {
            ...autoOrders,
            [device.device_id]: [...deviceAutoOrders, autoOrder]
          });
        }}
      />
    )
  ].filter(isTruthy);

  return (
    <Box p={2} flexGrow={1} width="100%">
      <Paper elevation={2}>
        <Box display="flex" flexDirection="column">
          <AutoOrderDeviceInfos device={device} editDeviceAutoOrder={editDeviceAutoOrder} />

          <Divider style={{ height: 2 }} />

          <Box p={1}>
            {intersperse(lines, index => (
              <Divider key={index} />
            ))}

            {form.errors.autoOrders && deviceAutoOrders.length === 0 && (
              <Alert severity="error">{t('auto_order_trigger_missing')}</Alert>
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

/** Insert a separator between every item */
function intersperse<T>(arr: T[], separator: (index: number) => T): T[] {
  return arr.reduce((acc, el, i) => (i === 0 ? [el] : [...acc, separator(i), el]), [] as T[]);
}

/**
 * Line representing a trigger rule
 */
const DeviceAutoOrderRule = ({
  autoOrder,
  device,
  onUpdate,
  onDelete,
  editable
}: {
  autoOrder: AutoOrderFormResponse['autoOrders']['device_id'][0];
  device: IDevice;
  onUpdate: (autoOrder: AutoOrderFormResponse['autoOrders']['device_id'][0]) => void;
  onDelete: () => void;
  editable: boolean;
}) => {
  const classes = useStyles();

  const { openDialog, dialog } = useAutoOrderTriggerDialog({
    device,
    onSubmit: onUpdate,
    defaultValues: autoOrder
  });

  return (
    <Box
      p={1}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Typography variant="body2">
          {autoOrder.data_type === 'missingWeight' && (
            <Trans
              i18nKey="auto_order_trigger_free_space_exceeds"
              values={{
                truck_count: Number(autoOrder.custom_integration?.nbOfOrder) ?? 1,
                quantity: autoOrder.max_value
              }}
              components={{
                b: <strong />
              }}
            />
          )}

          {autoOrder.data_type === 'level_t' && (
            <Trans
              i18nKey="auto_order_trigger_level_below"
              values={{
                truck_count: Number(autoOrder.custom_integration?.nbOfOrder) ?? 1,
                quantity: autoOrder.min_value
              }}
              components={{
                b: <strong />
              }}
            />
          )}
        </Typography>
      </Box>

      <Box flexGrow={1} />

      {editable && (
        <Box className={classes.ruleButtons}>
          <ConfirmDelete
            onConfirm={onDelete}
            objectToReturn={['rule']}
            size="small"
            btnVariant="outlined"
          />

          <Button color="primary" variant="outlined" onClick={openDialog}>
            <EditIcon />
          </Button>
          {dialog}
        </Box>
      )}
    </Box>
  );
};

/**
 * Line to add an auto-order rule to a device
 */
const DeviceAddAutoOrderRule = ({
  onCreate,
  device,
  deviceAutoOrders
}: {
  onCreate: (autoOrder: AutoOrderFormResponse['autoOrders']['device_id'][0]) => void;
  device: IDevice;
  deviceAutoOrders: AutoOrderFormResponse['autoOrders']['device_id'];
}) => {
  const classes = useStyles();

  const { openDialog, dialog } = useAutoOrderTriggerDialog({
    onSubmit: onCreate,
    device,
    defaultValues: { is_active: deviceAutoOrders[0]?.is_active }
  });

  return (
    <Box
      p={1}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box flexGrow={1} />

      <Typography variant="body2">
        <Trans
          i18nKey="auto_order_add_trigger_rule"
          components={{
            b: <strong />
          }}
        />
      </Typography>

      <Box className={classes.ruleButtons}>
        <Button color="primary" variant="contained" onClick={openDialog}>
          <AddIcon />
        </Button>
      </Box>

      {dialog}
    </Box>
  );
};

export default AutoOrderDeviceConfiguration;
