import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import Alert from '@material-ui/lab/Alert';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { KeyboardDatePicker } from '@material-ui/pickers';
import axios from 'axios';
import config from 'config/config';
import { getEnvApiUrl } from 'config/env';
import { IRootState } from 'config/store';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  convertGraphDataToCSV,
  exportTypeSilo,
  ICsvDataColumn,
  ICSVExportDef
} from 'shared/export/exportCSVDevices';
import { ReactComponent as ExportCSVIcon } from 'shared/icons/ExportCSV.svg';
import { IGetGraphDataRequest, nanolikeDataType } from 'shared/model/api.model';
import { IDevice } from 'shared/model/device.model';
import { IMe } from 'shared/model/user.model';
import { errorNotification, warningNotification } from 'shared/reducers/notifierSlice';
import { APP_LOCAL_DATE_FORMAT, formatDate } from 'shared/utils/date-utils';
import { ILabelValueOption } from 'shared/utils/select-utils';
import { workspaceIsIbc, workspaceIsSilo } from 'shared/utils/workspace-utils';

const apiUrl = getEnvApiUrl();
const exportCSV = config.exportCSV;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      [theme.breakpoints.up('md')]: {
        width: '350px;'
      }
    },
    exportCsvIcon: {
      height: '24px',
      width: '24px',
      fill: theme.palette.getContrastText(theme.palette.primary.main)
    },
    toggleButton: {
      height: '100%',
      width: '100px',
      '&.Mui-selected': {
        color: theme.palette.primary.main
      }
    },
    buttonGroup: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    delimiterSelection: {
      width: '100px'
    }
  })
);

const delimiterOptions: ILabelValueOption[] = [
  {
    label: 'devices_export.delimiter.semicolon',
    value: ';'
  },
  {
    label: 'devices_export.delimiter.comma',
    value: ','
  },
  {
    label: 'devices_export.delimiter.tab',
    value: '\t'
  },
  {
    label: 'devices_export.delimiter.space',
    value: ' '
  }
];

const start = moment().startOf('day').subtract(1, 'months');
const end = moment();

export type exportType = 'BOTH' | 'GEO' | 'DATA';
export const exportTypes: exportType[] = ['BOTH', 'GEO', 'DATA'];

export interface IExportDeviceCsvDialogProps {
  devices: IDevice[];
}
/**
 * TODO: REFACTO : TOO MUCH COMPLEXITY
 * @param props
 * @returns
 */
const ExportDeviceCsvDialog = (props: IExportDeviceCsvDialogProps) => {
  const dispatch = useDispatch();
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isIbc = workspaceIsIbc(settings);
  const isSilo = workspaceIsSilo(settings);

  const me = useSelector(({ authentication }: IRootState) => authentication.me) as IMe;
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Moment>(start);
  const [endDate, setEndDate] = useState<Moment>(end);

  const defaultDelimiter = me.configuration.preferred_language === 'fr' ? ';' : ',';
  const [delimiter, setDelimiter] = useState<string>(defaultDelimiter);

  const defaultDecimalDelimiter = me.configuration.preferred_language === 'fr' ? ',' : '.';
  const [decimalDelimiter, setDecimalDelimiter] = useState<',' | '.'>(defaultDecimalDelimiter);
  const [exportType, setExportType] = useState<exportType>(isSilo ? 'DATA' : 'BOTH');
  const [exporting, setExporting] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [typeState, setTypeState] = useState<{ [k in exportTypeSilo]: boolean }>({
    level: true,
    daily_analysis: false
  });

  const { devices } = props;

  const wsDataTypes = isIbc ? exportCSV.ibcDataTypes : exportCSV.siloDataTypes;

  const toggleDialog = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    if (open) {
      setStartDate(start);
      setEndDate(end);
      setErrorMsg('');
      setExporting(false);
    }
  }, [open]);

  useEffect(() => {
    const maxEndDate = moment(startDate).add(exportCSV.maxMonths, 'months');
    if (startDate.isAfter(endDate)) {
      setErrorMsg(t('export_data_inconsistent_dates'));
    } else if (endDate.isAfter(maxEndDate)) {
      setErrorMsg(t('export_data_invalid_range', { max_months: exportCSV.maxMonths }));
    } else if (!delimiter) {
      setErrorMsg(t('export_data_no_delimiter'));
    } else {
      setErrorMsg('');
    }
  }, [endDate, startDate, delimiter, t]);

  const onExport = () => {
    const defaultColumnDefs: ICsvDataColumn[] = [
      {
        label: t('datetime'),
        value: 'timestamp'
      },
      {
        label: t('device_reference'),
        value: 'device_reference'
      },
      {
        label: t('device_name'),
        value: 'device_name'
      }
    ];
    // @ts-ignore
    const finalDataTypes = wsDataTypes.filter(wsDataType => typeState[wsDataType] ?? true);
    const dataColumnDefs: ICsvDataColumn[] = finalDataTypes.map(dataType => {
      return {
        label: t(`${dataType}`),
        value: dataType
      };
    });

    const geoColumnDefs: ICsvDataColumn[] = [
      {
        label: t('position'),
        value: 'position'
      }
    ];

    let columns = [...defaultColumnDefs];
    let data_types: nanolikeDataType[] = [];
    if (exportType === 'BOTH' || exportType === 'DATA') {
      columns = columns.concat(dataColumnDefs);
      data_types = data_types.concat(finalDataTypes);
    }
    if (exportType === 'BOTH' || exportType === 'GEO') {
      columns = columns.concat(geoColumnDefs);
      data_types.push('position');
    }

    const toExport: IGetGraphDataRequest = {
      device_ids: devices.map(aDevice => aDevice.device_id),
      data_types,
      from_timestamp: startDate.toISOString(),
      to_timestamp: endDate.toISOString(),
      graph_id: 'export'
    };

    setExporting(true);

    const exportDef: ICSVExportDef = {
      columns,
      suffix: `${formatDate(startDate)}_${formatDate(endDate)}`,
      delimiter,
      decimalDelimiter,
      language: i18n.resolvedLanguage,
      types: Object.keys(typeState).filter(x => typeState[x as exportTypeSilo]) as exportTypeSilo[]
    };

    axios
      .post(`${apiUrl}/v1/get-graph-data`, toExport)
      .then(response => {
        setExporting(false);
        const data = response.data.data;
        if (data.length > 0) {
          setOpen(false);
          convertGraphDataToCSV(data, exportDef);
        } else {
          dispatch(warningNotification(t('no_data_available')));
        }
      })
      .catch(e => {
        setExporting(false);
        console.error('GET_GRAPH_DATA', e); // eslint-disable-line
        dispatch(errorNotification(t('export_data_error_request')));
      });
  };

  const onStartDateChange = (value: any) => {
    setStartDate(value);
  };

  const onEndDateChange = (value: any) => {
    setEndDate(value);
  };

  const onDelimiterChange = (e: any) => {
    setDelimiter(e.target.value);
  };

  const onDecimalDelimiterChange = (e: any) => {
    setDecimalDelimiter(e.target.value);
  };

  const onExportTypeChange = (e: any, selection: any) => {
    setExportType(selection);
  };

  const handleTypeSiloChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTypeState({ ...typeState, [event.target.name]: event.target.checked });
  };

  const canExport = exporting ? false : errorMsg.length === 0;
  const error = Object.keys(typeState).filter(k => typeState[k as exportTypeSilo]).length === 0;

  return (
    <>
      <Tooltip title={<Trans i18nKey="export_csv_data" values={{ count: devices.length }}></Trans>}>
        <Button variant="contained" color="primary" size="small" onClick={toggleDialog}>
          <ExportCSVIcon className={classes.exportCsvIcon} />
        </Button>
      </Tooltip>
      <Dialog fullScreen={fullScreen} open={open} onClose={toggleDialog}>
        <DialogTitle>{t('export_data_csv')}</DialogTitle>
        <DialogContent dividers className={classes.dialog}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            {exporting ? (
              <CircularProgress color="primary" />
            ) : (
              <>
                {!isSilo ? (
                  <Box marginBottom={2}>
                    <ToggleButtonGroup
                      value={exportType}
                      exclusive
                      onChange={onExportTypeChange}
                      className={classes.buttonGroup}
                    >
                      {exportTypes.map(anExportType => {
                        const title = t(`export_data_${anExportType}`);
                        return (
                          <ToggleButton
                            value={anExportType}
                            key={anExportType}
                            className={classes.toggleButton}
                          >
                            <Tooltip title={title} arrow>
                              <Box>{t(anExportType)}</Box>
                            </Tooltip>
                          </ToggleButton>
                        );
                      })}
                    </ToggleButtonGroup>
                  </Box>
                ) : (
                  <Box>
                    <FormControl required error={error} component="fieldset">
                      <FormLabel component="legend">{t('pick_at_least_one')}</FormLabel>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={typeState.level}
                            onChange={handleTypeSiloChange}
                            name="level"
                          />
                        }
                        label={t('level')}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={typeState.daily_analysis}
                            onChange={handleTypeSiloChange}
                            name="daily_analysis"
                          />
                        }
                        label={t('string_workspace_filling_unit', { value: t('daily_analysis') })}
                      />
                    </FormControl>
                  </Box>
                )}
                <Box>
                  <KeyboardDatePicker
                    variant="inline"
                    disableToolbar
                    format={APP_LOCAL_DATE_FORMAT}
                    value={startDate}
                    onChange={onStartDateChange}
                    label={t('export_data_csv_start_date')}
                    focused
                    disableFuture
                  />
                </Box>
                <Box>
                  <KeyboardDatePicker
                    variant="inline"
                    disableToolbar
                    format={APP_LOCAL_DATE_FORMAT}
                    value={endDate}
                    onChange={onEndDateChange}
                    label={t('export_data_csv_end_date')}
                    disableFuture
                  />
                </Box>
                <Box marginTop={2}>
                  <Grid container justify="center" alignItems="center" spacing={2}>
                    <Grid item sm={6}>
                      <FormControl>
                        <InputLabel id="delimiter-label">{t('delimiter')}</InputLabel>
                        <Select
                          className={classes.delimiterSelection}
                          labelId="delimiter-label"
                          id="delimiter-select"
                          value={delimiter}
                          onChange={onDelimiterChange}
                          disabled={exporting}
                        >
                          {delimiterOptions.map(delimiterOption => (
                            <MenuItem key={delimiterOption.value} value={delimiterOption.value}>
                              {t(delimiterOption.label)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl>
                        <InputLabel id="decimal-delimiter-label">
                          {t('decimal_delimiter')}
                        </InputLabel>
                        <Select
                          className={classes.delimiterSelection}
                          labelId="decimal-delimiter-label"
                          id="decimal-delimiter-select"
                          value={decimalDelimiter}
                          onChange={onDecimalDelimiterChange}
                          disabled={exporting}
                        >
                          <MenuItem key="0" value=",">
                            ,
                          </MenuItem>
                          <MenuItem key="1" value=".">
                            .
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
            {errorMsg && (
              <Box pt={2}>
                <Alert severity="warning">{errorMsg}</Alert>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDialog} startIcon={<CancelIcon />} disabled={exporting}>
            {t('cancel')}
          </Button>
          <Button
            onClick={onExport}
            color="primary"
            startIcon={<SaveIcon />}
            disabled={!canExport || error}
          >
            {t('export')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExportDeviceCsvDialog;
