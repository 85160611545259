import { Box, Button, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import { getEnvApiUrl } from 'config/env';
import axios from 'axios';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { errorNotification, successNotification } from 'shared/reducers/notifierSlice';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import NanolikeDateTimePicker from 'shared/widgets/form/nanolikeDateTimePicker';
import { ICalibration } from 'shared/model/calibration.model';
import { IDevice } from 'shared/model/device.model';

const apiUrl = getEnvApiUrl();
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        width: '20rem'
      }
    }
  })
);

interface ICalibrateDialogProps {
  open: boolean;
  calibration?: ICalibration;
  device?: IDevice;
  handleClose: () => void;
  onSucess?: () => void;
}
interface ICalibrationFormResponse {
  calibration_date: Moment;
  quantity: number;
}

const DeliveryDialog = (props: ICalibrateDialogProps) => {
  const capaMax = props?.calibration?.capa_max ?? props?.device?.capa_max ?? null;
  const idDevice = props?.calibration?.idDevice ?? props?.device?.device_id;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [saving, setSaving] = useState(false);
  const form = useForm<ICalibrationFormResponse>({
    mode: 'onChange',
    defaultValues: {
      quantity: props?.calibration?.quantity ?? 0,
      calibration_date: moment(props.calibration?.calibration_date) ?? moment().startOf('day')
    }
  });
  const save = async (responses: ICalibrationFormResponse) => {
    const silos = [
      {
        id: idDevice,
        tonnage: Number(responses.quantity)
        // content: { name: 'feed', type: 'ContentType' }
      }
    ];

    const toSend = props.calibration
      ? {
          delivery_date: moment(responses.calibration_date)?.toISOString(),
          delivery_quantity: Number(responses.quantity),
          source: 'delivery'
        }
      : {
          bypass_send_email: true,
          date: moment(responses.calibration_date)?.toISOString(),
          timeSlot: 'morning',
          source: 'delivery',
          silos
        };
    const call = props.calibration
      ? axios.patch(`${apiUrl}/v1/orders/${props.calibration.idCalibration}`, toSend)
      : axios.post(`${apiUrl}/v1/orders/`, toSend);
    try {
      setSaving(true);
      // @ts-ignore
      await call;
      dispatch(successNotification(t('orders.saveSuccess')));
      setSaving(false);
      form.reset();
      if (props.onSucess) props.onSucess();
      props.handleClose();
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(errorNotification(errorMsg));
      setSaving(false);
    }
  };

  const capaMaxMessage =
    capaMax && form.watch('quantity') && form.watch('quantity') > capaMax ? (
      <Trans i18nKey="orders.errors.capaMax" values={{ max: capaMax }}>
        Capamax
      </Trans>
    ) : (
      false
    );
  return (
    <Dialog fullScreen={fullScreen} open={props.open} onClose={props.handleClose}>
      <FormContext {...form}>
        <form className={classes.root} onSubmit={form.handleSubmit(save)} autoComplete="off">
          <DialogTitle>{t('orders.delivery')}</DialogTitle>
          <DialogContent>
            <Box>
              <TextField
                name={`quantity`}
                type="number"
                label={t('string_workspace_filling_unit', { value: t('orders.tonnage') })}
                InputProps={{ inputProps: { step: 'any' } }}
                inputRef={form.register({
                  min: {
                    value: 0.01,
                    message: <Trans i18nKey="should_be_above_zero">Should be greater than 0</Trans>
                  },
                  required: <Trans i18nKey="required_field">Required Field</Trans>,
                  validate: {
                    //@ts-ignore
                    doubleCapaMax: v =>
                      v < 2 * capaMax || (
                        <Trans i18nKey="orders.errors.capaMax" values={{ max: capaMax }}>
                          Capamax
                        </Trans>
                      )
                  }
                })}
                defaultValue="0"
                fullWidth
                disabled={saving}
                error={
                  (form.errors.quantity && form.errors.quantity ? true : false) || !!capaMaxMessage
                }
                helperText={
                  (form.errors.quantity && form.errors.quantity.message) || capaMaxMessage
                }
              />
            </Box>
            <Box>
              <NanolikeDateTimePicker
                name="calibration_date"
                value={moment(props.calibration?.calibration_date) ?? moment().startOf('day')}
                label={t('orders.date')}
                disabled={saving}
                required
                disableFuture
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={props.handleClose}
              variant="contained"
              color="default"
              startIcon={<CancelIcon />}
              disabled={saving}
            >
              {t('cancel')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<DoneIcon />}
              autoFocus
              disabled={saving}
            >
              {t('confirm')}
            </Button>
          </DialogActions>
        </form>
      </FormContext>
    </Dialog>
  );
};

export default DeliveryDialog;
