import { CircularProgress, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import debounce from 'lodash/debounce';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginRight: theme.spacing(3),
      paddingBottom: theme.spacing(0.5)
    },
    searchField: {
      marginTop: 0,
      width: '220px !important',
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        width: 'initial'
      }
    }
  })
);

interface ITitleWithSearchFieldProps {
  title: string;
  onChange: (...args: any) => any;
  autoFocus?: boolean;
  searchLabel?: string;
  placeholder?: string;
  debounceWait?: number;
  loading?: boolean;
}

const TitleWithSearchField: FC<ITitleWithSearchFieldProps> = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { debounceWait = 0, loading = false } = props;

  const debounceOnChange = debounce(props.onChange, debounceWait);

  const onChange = (e: any) => {
    debounceOnChange(e.target.value);
  };

  return (
    <Box display="flex" flexDirection="row" justifyContent="start">
      <Box flexGrow={0} className={classes.title}>
        {props.title}
      </Box>
      <Box display="flex" flexDirection="row">
        <TextField
          className={classes.searchField}
          autoFocus={props.autoFocus}
          size="small"
          label={props.searchLabel}
          placeholder={props.placeholder ?? t('search')}
          onChange={onChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {loading ? <CircularProgress size="18px" /> : <SearchIcon color="disabled" />}
              </InputAdornment>
            )
          }}
        />
        {props.children}
      </Box>
    </Box>
  );
};

export default TitleWithSearchField;
