import React, { useState } from 'react';
import { Box, Button, Paper, TextField, Typography, Grid, Divider } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { getEnvApiUrl } from 'config/env';
import Alert from '@material-ui/lab/Alert';
import SaveIcon from '@material-ui/icons/Save';
import axios from 'axios';
import moment from 'moment';
import { FormContext, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ICalibrateDevice } from 'shared/model/api.model';
import { errorNotification, successNotification } from 'shared/reducers/notifierSlice';
import NanolikeDateTimePicker from 'shared/widgets/form/nanolikeDateTimePicker';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import { IDevice } from 'shared/model/device.model';
import SelectDevice from 'shared/widgets/form/selectDevice'; // Import the SelectDevice component
import { ILabelValueOption } from 'shared/utils/select-utils';
import { getGroupsDevices } from 'shared/auth/auth-utils';
import { IRootState } from 'config/store';
import { CalibrationsTo } from '.';

const apiUrl = getEnvApiUrl();
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    },
    divider: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    content: {
      minWidth: '50vw',
      minHeight: '60vh',
      [theme.breakpoints.up('lg')]: {
        minWidth: '30vw'
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%'
      }
    },
    deviceInfo: {
      position: 'relative',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginBottom: theme.spacing(2)
    }
  })
);

const CalibrationLevelForm = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const allGroups = useSelector(({ group }: IRootState) => group.groups);
  const devices = getGroupsDevices(allGroups);
  const [selectedDevice, setSelectedDevice] = useState<IDevice | null>(null);
  const [updating, setUpdating] = useState(false);

  const form = useForm<ICalibrateDevice>({
    defaultValues: {
      calibration_level: 0,
      calibration_date: new Date()
    }
  });
  const { register, errors } = form;

  const save = async (responses: ICalibrateDevice) => {
    if (!selectedDevice) return;
    const toSend = {
      calibration_level: Number(responses.calibration_level),
      calibration_date: responses.calibration_date.toISOString()
    };
    try {
      setUpdating(true);
      await axios.post(`${apiUrl}/v1/devices/${selectedDevice.device_id}/calibrate`, toSend);
      dispatch(successNotification(t('calibrate_success')));
      setUpdating(false);
      form.reset();
      setSelectedDevice(null);
      history.push(CalibrationsTo);
    } catch (err) {
      setUpdating(false);
      const errorMsg = getRequestErrorMessage(err);
      dispatch(errorNotification(errorMsg));
    }
  };

  return (
    <Box p={1}>
      <Paper elevation={3}>
        <FormContext {...form}>
          <form className={classes.root} onSubmit={form.handleSubmit(save)} autoComplete="off">
            <Box display="flex" flexDirection="column" alignItems="center" p={1}>
              <Typography style={{ textAlign: 'center' }} variant="h5">
                {t('level')}
              </Typography>

              <Box
                sx={{
                  width: '50vw',
                  [theme.breakpoints.up('lg')]: {
                    width: '30vw'
                  },
                  [theme.breakpoints.down('sm')]: {
                    width: '100%'
                  },
                  mt: 2
                }}
              >
                <Alert
                  style={{ marginTop: theme.spacing(2) }}
                  severity="warning"
                  color="warning"
                  icon={<></>}
                >
                  {t('calib_level_form_subtitle')}
                </Alert>
              </Box>
            </Box>
            <Grid container justify="center" alignItems="center">
              <Grid item className={classes.content}>
                <Box marginBottom={2}>
                  <SelectDevice
                    name="device"
                    label={t('select_device')}
                    deviceGroupLabel={t('device')}
                    disabled={updating}
                    required
                    useRefDevice
                    onSelectionChange={(value: ILabelValueOption<string>) => {
                      const device = devices.find(d => d.device_reference === value.value);
                      setSelectedDevice(device || null);
                    }}
                    validate={(value: any) => {
                      if (!value) return <Trans i18nKey="required_field">Required Field</Trans>;
                      return true;
                    }}
                  />
                </Box>

                {selectedDevice && (
                  <Paper
                    component={Box}
                    elevation={3}
                    key={selectedDevice.device_reference}
                    className={classes.deviceInfo}
                  >
                    <TextField
                      name="calibration_level"
                      type="number"
                      label={t('string_workspace_filling_unit', { value: t('level') })}
                      InputProps={{ inputProps: { step: 0.1 } }}
                      inputRef={register({
                        required: <Trans i18nKey="required_field">Required Field</Trans>,
                        min: {
                          value: 0,
                          message: (
                            <Trans i18nKey="should_be_above_zero">Should be greater than 0</Trans>
                          )
                        },
                        max: {
                          value: selectedDevice.capa_max,
                          message: (
                            <Trans
                              i18nKey="should_be_equal_or_less_than"
                              values={{ value: selectedDevice.capa_max }}
                            >
                              Should be equal or less than
                            </Trans>
                          )
                        }
                      })}
                      error={errors.calibration_level ? true : false}
                      helperText={errors.calibration_level && errors.calibration_level.message}
                      disabled={updating}
                      fullWidth
                      autoFocus
                    />
                    <NanolikeDateTimePicker
                      name="calibration_date"
                      value={moment()}
                      label={t('calibration_date')}
                      disabled={updating}
                      required
                      disableFuture
                    />
                    <Box marginTop={2} marginBottom={1}>
                      <Alert severity="info">{t('calibrate_warning')}</Alert>
                    </Box>
                  </Paper>
                )}
              </Grid>
            </Grid>
            <Divider variant="middle" className={classes.divider} />
            <Box display="flex" justifyContent="space-between" marginTop={2}>
              <Button
                component={Link}
                to={CalibrationsTo}
                startIcon={<CancelIcon />}
                variant="contained"
                disabled={updating}
              >
                {t('cancel')}
              </Button>
              <Button
                type="submit"
                color="primary"
                startIcon={<SaveIcon />}
                variant="contained"
                disabled={updating || !selectedDevice}
              >
                {t('save')}
              </Button>
            </Box>
          </form>
        </FormContext>
      </Paper>
    </Box>
  );
};

export default CalibrationLevelForm;
