import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl } from 'config/env';
import { AppThunk } from 'config/store';
import { ICalibrateDevice, IDataType, IDataTypesForDevicesResponse } from 'shared/model/api.model';
import { IDevice } from 'shared/model/device.model';
import { IGroup } from 'shared/model/group.model';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import {
  convertDatesToServer,
  convertDateTimesToServer,
  convertDeviceDateFromServer
} from 'shared/utils/date-utils';
import { fetchGroups, updateGroupsWithDevices } from './groupSlice';
import { errorNotification } from './notifierSlice';

const initialState = {
  loading: false,
  loadingTypes: false,
  errorMessage: '',
  devices: [] as IDevice[],
  device: null as IDevice | null,
  deviceDataTypes: [] as IDataType[],
  updating: false,
  updateSuccess: false
};

interface IDevicesResponse {
  rowCount: number;
  page: number;
  pageSize: number;
  results: IDevice[];
}

export type DeviceState = typeof initialState;

export const slice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    updateDevicesWithGroupReducer: (state, action: PayloadAction<IGroup[]>) => {
      state.devices.forEach(device => {
        device.groups = [];
        const groupIds: string[] = [];
        device.groupMembership?.forEach(gm => {
          const group = action.payload.find(group => group.group_id === gm.group_id);
          if (group) {
            device.groups.push(group);
            groupIds.push(group.group_id);
            if (group.is_poi) {
              device.farm_name = group.group_name;
              device.farm_id = group.group_id;
            }
          }
        });
        device.group_ids = groupIds;
      });
    },
    updateDevicesFromGroupReducer: (
      state,
      action: PayloadAction<{ group_id: string; devices?: IDevice[] }>
    ) => {
      action.payload.devices?.forEach(device => {
        const index = state.devices.findIndex(item => item.device_id === device.device_id);
        state.devices[index] = device;
      });
      const devicesThatAreAlreadyIngroups = state.devices.filter(device => {
        return device.groupMembership?.some(gm => gm.group_id === action.payload.group_id);
      });
      devicesThatAreAlreadyIngroups.forEach(device => {
        if (
          !action.payload.devices ||
          !action.payload.devices.map(item => item.device_id).includes(device.device_id)
        ) {
          const index = state.devices.findIndex(item => item.device_id === device.device_id);
          state.devices[index].groups = state.devices[index].groups.filter(
            g => g.group_id !== action.payload.group_id
          );
        }
      });
    },
    fetchDevicesStart: state => {
      state.loading = true;
      state.errorMessage = '';
      state.updateSuccess = false;
    },
    fetchDevicesFailed: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.payload;
    },
    fetchDevicesSuccess: (state, action: PayloadAction<IDevicesResponse>) => {
      const devices = action.payload.results;

      devices.forEach(device => {
        convertDeviceDateFromServer(device);
      });
      state.loading = false;
      state.devices = devices;
    },
    fetchDeviceSuccess: (state, action: PayloadAction<IDevice>) => {
      const device = action.payload;
      convertDeviceDateFromServer(device);
      state.loading = false;
      state.device = device;
    },
    updateDeviceStart: state => {
      state.updating = true;
      state.errorMessage = '';
      state.updateSuccess = false;
    },
    updateDeviceFailed: (state, action: PayloadAction<string>) => {
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.payload;
    },
    updateDeviceSuccess: (state, action: PayloadAction<IDevice | null>) => {
      state.updating = false;
      state.updateSuccess = true;
      state.device = action.payload;
    },
    calibrateDeviceSuccess: state => {
      state.updating = false;
      state.updateSuccess = true;
    },
    fetchDevicesDataTypesStart: state => {
      state.loadingTypes = true;
      state.errorMessage = '';
    },
    fetchDevicesDataTypesFailed: (state, action: PayloadAction<string>) => {
      state.loadingTypes = false;
      state.errorMessage = action.payload;
    },
    fetchDevicesDataTypesSuccess: (state, action: PayloadAction<IDataTypesForDevicesResponse>) => {
      state.loadingTypes = false;
      state.deviceDataTypes = action.payload.data;
    }
  }
});

export default slice.reducer;

//Actions
const {
  fetchDevicesStart,
  fetchDevicesFailed,
  fetchDevicesSuccess,
  fetchDeviceSuccess,
  updateDeviceStart,
  updateDeviceFailed,
  updateDeviceSuccess,
  calibrateDeviceSuccess,
  fetchDevicesDataTypesStart,
  fetchDevicesDataTypesFailed,
  fetchDevicesDataTypesSuccess,
  updateDevicesWithGroupReducer,
  updateDevicesFromGroupReducer
} = slice.actions;

const apiUrl = getEnvApiUrl();

export const updateDevicesFromGroup =
  (group_id: string, devices?: IDevice[]): AppThunk =>
  dispatch => {
    dispatch(updateDevicesFromGroupReducer({ group_id, devices }));
  };
export const updateDevicesWithGroup = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const groups = state.group.groups;
  dispatch(updateDevicesWithGroupReducer(groups));
  dispatch(updateGroupsWithDevices());
};

export const fetchDevices = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchDevicesStart());
    const response: AxiosResponse<IDevice[]> = await axios.get(
      `${apiUrl}/v1/devices/?withRelated=nextOrder,deviceContent,groupMembership,combinedDevice,devicesCombined`
    );

    const devices = response.data;
    const result: IDevicesResponse = {
      rowCount: devices.length,
      page: 1,
      pageSize: devices.length,
      results: devices
    };

    dispatch(fetchDevicesSuccess(result));
  } catch (error) {
    const errorMsg = getRequestErrorMessage(error);
    dispatch(fetchDevicesFailed(errorMsg));
    dispatch(errorNotification(`${errorMsg}`));
  }
};

export const fetchDevice =
  (id: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(fetchDevicesStart());
      const response: AxiosResponse<IDevice> = await axios.get(`${apiUrl}/v1/devices/${id}/`);
      dispatch(fetchDeviceSuccess(response.data));
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(fetchDevicesFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const updateDevice =
  (device: Partial<IDevice>): AppThunk =>
  async dispatch => {
    try {
      const toSend = { ...device };
      convertDatesToServer(toSend);

      dispatch(updateDeviceStart());
      const response: AxiosResponse = await axios.patch(
        `${apiUrl}/v1/devices/${device.device_id}/`,
        toSend
      );
      dispatch(updateDeviceSuccess(response.data));
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateDeviceFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const calibrateDevice =
  (deviceId: string, calibration: ICalibrateDevice): AppThunk =>
  async dispatch => {
    try {
      convertDateTimesToServer(calibration);
      dispatch(updateDeviceStart());
      await axios.post(`${apiUrl}/v1/devices/${deviceId}/calibrate`, calibration);
      dispatch(calibrateDeviceSuccess());
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateDeviceFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const removeGroupFromDevice =
  (device: IDevice, fetch: boolean = true): AppThunk =>
  async dispatch => {
    try {
      const toSend = { ...device };
      convertDatesToServer(toSend);

      dispatch(updateDeviceStart());
      const response: AxiosResponse = await axios.patch(
        `${apiUrl}/v1/devices/${device.device_id}/`,
        toSend
      );
      dispatch(updateDeviceSuccess(response.data));
      if (fetch) {
        dispatch(fetchDevices());
      }
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateDeviceFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const addGroupsToDevice =
  (groups: { group_ids: string[] }, device: IDevice): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateDeviceStart());
      const response: AxiosResponse = await axios.patch(
        `${apiUrl}/v1/devices/${device.device_id}/`,
        groups
      );
      dispatch(updateDeviceSuccess(response.data));
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateDeviceFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const fetchDevicesDataTypes =
  (device_ids: string[]): AppThunk =>
  async dispatch => {
    try {
      dispatch(fetchDevicesDataTypesStart());
      if (device_ids.length > 0) {
        const response: AxiosResponse<IDataTypesForDevicesResponse> = await axios.post(
          `${apiUrl}/v1/data-types-for-devices`,
          { device_ids }
        );
        dispatch(fetchDevicesDataTypesSuccess(response.data));
      } else {
        const emptyResponse: IDataTypesForDevicesResponse = {
          data: []
        };
        dispatch(fetchDevicesDataTypesSuccess(emptyResponse));
      }
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(fetchDevicesDataTypesFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const combineDevices =
  (deviceName: string, devices: IDevice[]): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateDeviceStart());
      await axios.post(`${apiUrl}/v1/devices/combine`, {
        device_name: deviceName,
        device_ids: devices.map(item => item.device_id)
      });
      dispatch(updateDeviceSuccess(null));
      dispatch(fetchGroups());
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateDeviceFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const unCombineDevices =
  (device: IDevice): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateDeviceStart());
      await axios.delete(`${apiUrl}/v1/devices/${device.device_id}`);
      dispatch(updateDeviceSuccess(null));
      dispatch(fetchGroups());
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateDeviceFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };
