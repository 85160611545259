import { createTheme as createMuiTheme, Theme, ThemeOptions } from '@material-ui/core';
import { enUS, esES, frFR, itIT, ruRU } from '@material-ui/core/locale';
import { lighten } from '@material-ui/core/styles';
import { useMemo } from 'react';
import { createTheme } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ISettings } from 'shared/reducers/settingsSlice';
import { ReactComponent as SiloConnectHeader } from './SiloConnectHeader.svg';
import { ReactComponent as TankConnectHeader } from './TankConnectHeader.svg';
import { ReactComponent as NanolikeHeader } from './NanolikeHeader.svg';
import siloBackground from './silo_background.png';
import ibcBackground from './ibc_background.png';
import { ReactComponent as RayOrange } from './LandingScreenOrangeRay.svg';
import { ReactComponent as RayNanolike } from './LandingScreenRay.svg';
import { ReactComponent as RayPurple } from './LandingScreenPurpleRay.svg';
import siloConnectIconBlue from './Picto-SiloConnect.png';
import tankConnectIconBlue from './Picto-TankConnect.png';
import nanolikeSmallLogo from './nanolikeLogo.png';
import { IRootState } from './store';

const capitalize = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

const logoForUseCase = {
  ibc: TankConnectHeader,
  silo_industry: SiloConnectHeader,
  default: NanolikeHeader
};
const ray = {
  ibc: RayPurple,
  silo_industry: RayOrange,
  default: RayNanolike
};

const backgroundImage = {
  ibc: ibcBackground,
  silo_industry: siloBackground,
  default: siloBackground
};

const titleForUseCase = {
  silo: `BinConnect - ${capitalize(window.env.workspace)}`,
  ibc: `TankConnect - ${capitalize(window.env.workspace)}`,
  silo_industry: `SiloConnect - ${capitalize(window.env.workspace)}`,
  default: `Nanolike - ${capitalize(window.env.workspace)}`
};

const blueIconForUseCase = {
  ibc: tankConnectIconBlue,
  silo_industry: siloConnectIconBlue,
  default: nanolikeSmallLogo
};

const welcomeText = {
  ibc: 'welcome_text_ibc',
  silo_industry: 'welcome_text_silo_industry',
  default: 'welcome_text'
};

const welcomeDescription = {
  ibc: 'welcome_description_ibc',
  silo_industry: 'welcome_description_silo_industry',
  default: 'welcome_description'
};

export interface INanolikeCustomTheme {
  title: string;
  muiTheme: Theme;
  backgroundImage: string;
  logoSmall: string;
  headerMenuLogo: typeof SiloConnectHeader;
  ray: typeof RayOrange;
  welcomeText: string;
  welcomeDescription: string;
  headerMenuTitle?: string;
  // backgroundLoginColor: { backgroundColor: string; color: string, display: string, alignItems: string };
  backgroundLoginColor: string;
  headerMenuBackgroundColor: string;
}
const brandColor: { [key: string]: string } = {
  silo_industry: '#db7d55',
  ibc: '#a13a99',
  default: '#000000'
};

export const getNanolikeTheme = (settings: ISettings | null) => {
  const theme: ThemeOptions = {
    palette: {
      // primary: settings && settings.primary? { main: settings.primary as string } : {main: "#00FF00"},
      primary: { main: '#0F0B37' },
      // primary: nanolikePrimaryColors,
      secondary: {
        main: '#E83E6D',
        light: '#FCDCE5',
        contrastText: '#fff'
      },
      warning: {
        main: '#F49855',
        light: '#FFF3E0',
        contrastText: '#fff'
      },
      error: {
        main: '#E83E6D',
        light: '#FCDCE5',
        contrastText: '#fff'
      },
      success: {
        main: '#42A37F',
        light: '#E8F5E9',
        contrastText: '#fff'
      },
      info: {
        main: '#2E48A7',
        light: '#DFE4F6',
        contrastText: '#fff'
      }
    },

    props: {
      MuiButton: {
        size: 'small'
      },
      MuiFilledInput: {
        margin: 'dense'
      },
      MuiFormControl: {
        margin: 'dense'
      },
      MuiFormHelperText: {
        margin: 'dense'
      },
      MuiIconButton: {
        size: 'small'
      },
      MuiInputBase: {
        margin: 'dense'
      },
      MuiInputLabel: {
        margin: 'dense'
      },
      MuiListItem: {
        dense: true
      },
      MuiOutlinedInput: {
        margin: 'dense'
      },
      MuiFab: {
        size: 'small'
      },
      MuiTable: {
        size: 'small'
      },
      MuiTextField: {
        margin: 'dense'
      },
      MuiToolbar: {
        variant: 'dense'
      }
    },
    overrides: {
      MuiSwitch: {
        root: {
          padding: 8,
          '& .MuiSwitch-track': {
            borderRadius: 22 / 2,
            '&::before, &::after': {
              content: '""',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              width: 16,
              height: 16
            },
            '&::before': {
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="white" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
              left: 12
            },
            '&::after': {
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="white" d="M19,13H5V11H19V13Z" /></svg>')`,
              right: 12
            }
          },
          '& .MuiSwitch-thumb': {
            boxShadow: 'none',
            width: 16,
            height: 16,
            margin: 2
          }
        }
      },
      MuiTooltip: {
        tooltip: {
          fontSize: '13px'
        }
      },
      MuiTabs: {
        root: {
          padding: 0
        },
        indicator: {
          display: 'flex',
          backgroundColor: brandColor[settings?.useCase || 'default']
        }
      }
    }
  };
  return theme;
};

const useConfigTheme = (): INanolikeCustomTheme => {
  const { i18n, t } = useTranslation();
  const settings = useSelector(({ settings }: IRootState) => settings.settings);

  const theme = useMemo(() => {
    const theme: ThemeOptions = getNanolikeTheme(settings);
    let locale = enUS;
    switch (i18n.resolvedLanguage) {
      case 'fr':
        locale = frFR;
        break;
      case 'es':
        locale = esES;
        break;
      case 'it':
        locale = itIT;
        break;
      case 'ru':
        locale = ruRU;
        break;
      default:
        locale = enUS;
        break;
    }
    const muiTheme = createMuiTheme(theme, locale);
    const headerContextBackground = lighten(muiTheme.palette.primary.light, 0.8);
    const headerContextText = muiTheme.palette.text;

    createTheme('nanolike', {
      context: {
        background: headerContextBackground,
        text: headerContextText
      }
    });
    const result: INanolikeCustomTheme = {
      muiTheme,
      //@ts-ignore
      title: titleForUseCase[settings?.useCase || 'default'],
      //@ts-ignore
      backgroundImage: backgroundImage[settings?.useCase || 'default'],
      //@ts-ignore
      ray: ray[settings?.useCase || 'default'],
      //@ts-ignore
      logoSmall: blueIconForUseCase[settings?.useCase || 'default'],
      //@ts-ignore
      headerMenuLogo: logoForUseCase[settings?.useCase || 'default'],
      //@ts-ignore
      welcomeText: t(welcomeText[settings?.useCase || 'default']),
      //@ts-ignore
      welcomeDescription: t(welcomeDescription[settings?.useCase || 'default']),
      backgroundLoginColor: muiTheme.palette.primary.main,
      headerMenuBackgroundColor: muiTheme.palette.primary.main
    };
    return result;
  }, [i18n.resolvedLanguage, settings, t]);

  return theme;
};

export default useConfigTheme;
