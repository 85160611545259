import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import debounce from 'lodash/debounce';
import { maxDay } from 'modules/dashboard/vizualisation/NanoMapViz';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      paddingTop: theme.spacing(1)
    }
  })
);

interface IMapDaySliderProps {
  isIbc: boolean;
  defaultValue: number;
  onDayChange: (day: number) => void;
}

const MapDaySlider = (props: IMapDaySliderProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const { isIbc, defaultValue, onDayChange } = props;

  if (!isIbc) {
    return null;
  }

  const availableMarks = {
    0: t('today'),
    1: '+1',
    2: '+2',
    3: '+3 ',
    4: '+4',
    5: '+5',
    6: '+6',
    7: '+7'
  };

  return (
    <Box width="400px" marginBottom={2}>
      <Card>
        <CardContent className={classes.card}>
          <Box textAlign="center" marginBottom={1} fontSize="0.8rem">
            {t('sliderLegend')}
          </Box>
          <Box paddingX="30px">
            <Slider
              trackStyle={{ backgroundColor: theme.palette.info.main }}
              railStyle={{ backgroundColor: '#CCCCCC' }}
              handleStyle={{
                borderColor: '#CCCCCC',
                height: 14,
                width: 14,
                marginTop: -4,
                backgroundColor: theme.palette.info.main
              }}
              included={false}
              activeDotStyle={{ borderColor: theme.palette.info.main }}
              step={null}
              min={0}
              max={maxDay}
              defaultValue={defaultValue}
              marks={availableMarks}
              onChange={debounce(onDayChange, 200)}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default MapDaySlider;
