import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDevice } from 'shared/model/device.model';
import DeliveryDialog from './deliveryDialog';
import { LocalShipping } from '@material-ui/icons';

interface IDeliveryBtnProps {
  device: IDevice;
}

const DeliveryBtn = ({ device }: IDeliveryBtnProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && <DeliveryDialog device={device} open={open} handleClose={() => setOpen(false)} />}
      <Button
        startIcon={<LocalShipping style={{ fontSize: 24 }} />}
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        {t('missing_delivery')}
      </Button>
    </>
  );
};

export default DeliveryBtn;
