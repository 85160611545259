import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import LinkIcon from '@material-ui/icons/Link';
import React from 'react';
import { ReactComponent as DefaultIbcIcon } from 'shared/icons/Ibc.svg';
import { ReactComponent as IbcErrorIcon } from 'shared/icons/IbcError.svg';
import { ReactComponent as IbcLevelProblemIcon } from 'shared/icons/IbcLevelProblem.svg';
import siloIconSvg, { ReactComponent as SiloIcon } from 'shared/icons/SiloIcon.svg';
import ibcIconSvg from 'shared/icons/IbcIcon.svg';
import { IDeviceStatus, IDevice } from 'shared/model/device.model';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      width: '32px',
      height: '32px'
    },
    combineIcon: {
      width: '24px',
      height: '24px',
      color: '#555',
      marginLeft: '5px',
      marginRight: '5px'
    }
  })
);

interface IDeviceIconProps {
  device: IDevice;
}

export const DeviceIconPng = (props: { is_silo: boolean }) => {
  const { is_silo } = props;
  if (is_silo) return siloIconSvg;
  return ibcIconSvg;
};

export const IbcIcon = ({ status = 'ok' }: { status?: IDeviceStatus }) => {
  const classes = useStyles();

  if (status === 'ok') return <DefaultIbcIcon className={classes.icon} />;
  if (status === 'level_problem') return <IbcLevelProblemIcon className={classes.icon} />;
  return <IbcErrorIcon className={classes.icon} />;
};

export const CombineIcon = () => {
  const classes = useStyles();
  return <LinkIcon className={classes.combineIcon} />;
};

const DeviceIcon = (props: IDeviceIconProps) => {
  const { device } = props;

  if (device.is_combined) {
    return <CombineIcon />;
  } else if (device.is_silo) {
    return <SiloIcon />;
  } else {
    return <IbcIcon status={device.status} />;
  }
};

export default DeviceIcon;
