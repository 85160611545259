import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import { IRootState } from 'config/store';
import React, { useCallback, useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IDevice } from 'shared/model/device.model';
import { addGroupsToDevice } from 'shared/reducers/devicesSlice';
import { sortOptionsByLabel } from 'shared/utils/data-utils';
import { ILabelValueOption } from 'shared/utils/select-utils';
import MultipleAutoComplete from 'shared/widgets/form/multipleAutocomplete';

interface IFormResponse {
  groups: ILabelValueOption[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      [theme.breakpoints.up('sm')]: {
        minWidth: '580px',
        minHeight: '200px'
      }
    },
    autoComplete: {
      marginBottom: theme.spacing(2)
    }
  })
);

export interface IAddGroupToDeviceDialogProps {
  devices: IDevice[];
  onSuccess?: Function;
}

const AddGroupToDeviceDialog = (props: IAddGroupToDeviceDialogProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const groups = useSelector(({ group }: IRootState) => group.groups);
  const updateSuccess = useSelector(({ devices }: IRootState) => devices.updateSuccess);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const form = useForm<IFormResponse>();

  const { devices, onSuccess } = props;

  const toggleDialog = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    if (updateSuccess === true && open) {
      if (onSuccess) {
        onSuccess();
      }
      setOpen(false);
    }
  }, [onSuccess, open, updateSuccess]);

  const onSubmit = form.handleSubmit(values => {
    const groupsToAdd = values.groups.map(aGroup => aGroup.value as string);

    devices.forEach(device => {
      const existingGroups = device.group_ids ? device.group_ids : [];
      const group_ids = [...existingGroups, ...groupsToAdd];

      dispatch(addGroupsToDevice({ group_ids }, device));
    });
  });

  const unionGroups: string[] = devices.reduce((agg: string[], currentDevice) => {
    if (currentDevice.group_ids) {
      currentDevice.group_ids.forEach(groupId => {
        if (!agg.includes(groupId)) {
          agg.push(groupId);
        }
      });
    }
    return agg;
  }, []);

  const groupOptions = groups
    .filter(aGroup => aGroup.is_poi === false)
    .reduce((agg: ILabelValueOption[], current) => {
      if (!unionGroups.includes(current.group_id)) {
        agg.push({
          label: current.group_name,
          value: current.group_id
        });
      }
      return agg;
    }, []);
  groupOptions.sort(sortOptionsByLabel);

  return (
    <>
      <Tooltip title={<Trans i18nKey="add_device_to_group" count={devices.length}></Trans>}>
        <Button variant="contained" color="primary" size="small" onClick={toggleDialog}>
          <AddIcon />
        </Button>
      </Tooltip>
      <Dialog fullScreen={fullScreen} open={open} onClose={toggleDialog}>
        <FormContext {...form}>
          <form onSubmit={onSubmit} autoComplete="off">
            <DialogTitle>
              {t('update_group_to_device', {
                count: devices.length,
                name: devices[0] ? devices[0].device_name : ''
              })}
            </DialogTitle>
            <DialogContent className={classes.content} dividers>
              <MultipleAutoComplete
                label={t('add_group_to_device')}
                name="groups"
                options={groupOptions}
                autoFocus
                className={classes.autoComplete}
                validate={(value: any) => {
                  if (!value || value.length === 0) {
                    return <Trans i18nKey="required_field">Required Field</Trans>;
                  }
                  return true;
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={toggleDialog} startIcon={<CancelIcon />}>
                {t('cancel')}
              </Button>
              <Button type="submit" color="primary" startIcon={<SaveIcon />}>
                {t('save')}
              </Button>
            </DialogActions>
          </form>
        </FormContext>
      </Dialog>
    </>
  );
};

export default AddGroupToDeviceDialog;
