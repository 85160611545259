import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl } from 'config/env';
import { IRootState } from 'config/store';
import DeviceMap from 'modules/devices/deviceMap';
import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import useInterval from 'shared/hooks/useInterval';
import { IGetGraphDataResponse, IGraphData, nanolikeDataType } from 'shared/model/api.model';
import { IDevice } from 'shared/model/device.model';
import { IGraph } from 'shared/model/graph.model';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import { useWorkspaceType, workspaceIsIbc } from 'shared/utils/workspace-utils';
import Loading from 'shared/widgets/loading';
import { convertToApiFilters } from '../grid/filter/filterGraph.model';

const apiUrl = getEnvApiUrl();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 'calc(100% - 15px)',
      marginTop: theme.spacing(0.5)
    },
    map: {
      position: 'relative',
      height: '100%'
    }
  })
);

export const maxDay = 7;

export const getLevelJPlusXPercentTypes = () => {
  const result: nanolikeDataType[] = [];
  for (let i = 1; i <= maxDay; i++) {
    const jplusX = `levelJPlus${i}Percent` as nanolikeDataType;
    result.push(jplusX);
  }
  return result;
};

let dataTypes: nanolikeDataType[] = ['level_percent', 'lastMessageReceived'];
// dataTypes = dataTypes.concat(getLevelJPlusXPercentTypes());

interface INanoMapVizState {
  loading: boolean;
  loadSuccess: boolean;
  errorMessage: string;
  graphData: IGraphData[];
  device?: IDevice;
}

const initialState: INanoMapVizState = {
  loading: false,
  loadSuccess: false,
  errorMessage: '',
  graphData: []
};

interface INanoMapVizProps {
  graph: IGraph;
  preview?: boolean;
}

const NanoMapViz = (props: INanoMapVizProps) => {
  const classes = useStyles();
  const groups = useSelector(({ group }: IRootState) => group.groups);
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isIbc = workspaceIsIbc(settings);
  const workspaceType = useWorkspaceType(settings);

  const [state, setState] = useState<INanoMapVizState>(initialState);

  const { graph, preview } = props;

  if (!isIbc) dataTypes.push('level_t');

  const loadGraphData = useCallback(async () => {
    let ids = [...graph.device_ids];
    graph.group_ids.forEach((aGroupId: string) => {
      const group = groups.find(aGroup => aGroup.group_id === aGroupId);
      if (group && group.devices) {
        const deviceIds = group.devices.map(aDevice => aDevice.device_id);
        ids = ids.concat(deviceIds);
      }
    });

    if (ids.length === 0) {
      setState({ ...initialState });
    } else {
      const data_types = graph.device_data_types.concat(dataTypes);
      const body: any = {
        data_types,
        device_ids: ids,
        is_last_value: graph.is_last_value
      };
      if (!preview) {
        body.client_filter = convertToApiFilters(
          props.graph.type,
          workspaceType,
          graph.client_filter ?? []
        );
      }

      try {
        setState({
          ...initialState,
          loading: true,
          graphData: []
        });
        const response: AxiosResponse<IGetGraphDataResponse> = await axios.post(
          `${apiUrl}/v1/get-graph-data`,
          body
        );
        const graphData = response.data.data;

        setState({
          graphData,
          loading: false,
          loadSuccess: true,
          errorMessage: ''
        });
      } catch (e) {
        setState({
          ...initialState,
          errorMessage: getRequestErrorMessage(e)
        });
      }
    }
  }, [graph, groups, preview, props.graph.type, workspaceType]);

  useInterval(loadGraphData);

  const onLoadForecast = async (day: number) => {
    if (day > 0) {
      // we call forecast
      if (!dataTypes.includes('levelJPlus1Percent')) {
        // if they are not included in datatypes, add it before calling data
        dataTypes = dataTypes.concat(getLevelJPlusXPercentTypes());
        loadGraphData();
      }
    } else {
      if (dataTypes.includes('levelJPlus1Percent')) {
        // reinitialize datatype without forecast
        dataTypes = ['level_percent', 'lastMessageReceived'];
        loadGraphData();
      }
    }
  };

  return (
    <Box className={classes.root}>
      {state.loading && <Loading />}
      {state.graphData && (
        <Box className={classes.map}>
          <DeviceMap
            data={state.graphData}
            onLoadForecast={onLoadForecast}
            errorOnLoad={state.errorMessage}
          />
        </Box>
      )}
    </Box>
  );
};

export default NanoMapViz;
