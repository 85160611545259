/**
 * To use as an array filter mostly, to remove falsy values from an array and
 * get a cleanly typed array.
 *
 * Example:
 * const a: Array<number | null | undefined | false> = [1, 2, 3, 0, null, undefined, false]
 * const b: Array<number> = a.filter(isTruthy)
 */
export default function isTruthy<T>(value?: T | undefined | null | false): value is T {
  return !!value;
}
