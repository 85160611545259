import React, { useMemo } from 'react';
import { useForm, FormContext, Controller } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'config/store';
import { IDevice } from 'shared/model/device.model';
import { ILabelValueOption } from 'shared/utils/select-utils';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  ListItemText,
  Box,
  useTheme,
  DialogContentText
} from '@material-ui/core';
import MultipleAutoComplete from 'shared/widgets/form/multipleAutocomplete';
import NanolikeDatePicker from 'shared/widgets/form/nanolikeDatePicker';
import SingleAutoComplete from 'shared/widgets/form/singleAutocomplete';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { Moment } from 'moment';
import DeviceIcon from 'shared/widgets/devices/deviceIcon';
import { ReactComponent as PlantIcon } from 'shared/icons/PlantIcon.svg';

export interface IDeviceFormResponse {
  groups: ILabelValueOption<string>[];
  site: ILabelValueOption<string>[];
  device_name: string;
  device_description: string;
  product: ILabelValueOption<string>;
  device_install_date: Moment;
  device_fabrication_date: Moment;
  device_next_maintenance_date: Moment;
  galileou_enabled: boolean;
}

export interface IDeviceFormProps {
  device: IDevice;
  onSubmit: (data: IDeviceFormResponse) => void;
  onCancel: () => void;
  editContentOnly?: boolean;
}

const DeviceForm: React.FC<IDeviceFormProps> = ({
  device,
  onSubmit,
  onCancel,
  editContentOnly
}) => {
  const { t } = useTranslation();
  const groups = useSelector(({ group }: IRootState) => group.groups);
  let products = useSelector(({ product }: IRootState) => product.products);
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const filterContentByGroup =
    settings.find(s => s.key === 'filterContentByGroup')?.value === 'true';
  const updating = useSelector(({ devices }: IRootState) => devices.updating);
  const hasAutoOrder = device.has_auto_order !== null;
  const theme = useTheme();

  const groupOptions: ILabelValueOption<string>[] = groups
    .filter(aGroup => aGroup.is_poi === false)
    .map(aGroup => ({
      label: aGroup.group_name,
      value: aGroup.group_id
    }));

  const productsOptions: ILabelValueOption<string>[] = useMemo(
    () =>
      products
        .filter(
          d =>
            !filterContentByGroup ||
            d.groups.map(g => g.group_id).some(g => device.group_ids?.includes(g))
        )
        .map(product => ({
          label: product.device_content,
          value: product.id
        })),
    [products, filterContentByGroup, device.group_ids]
  );

  const defaultProductOption = productsOptions.find(
    product => product.value === device.device_content_id
  );

  const form = useForm<IDeviceFormResponse>({
    defaultValues: {
      device_name: device.device_name,
      device_description: device.device_description,

      groups: groupOptions.filter(g => device.group_ids?.includes(g.value)),
      product: defaultProductOption,
      galileou_enabled: device.galileou_enabled
    }
  });
  const dirtyFields = form.formState.dirtyFields;

  return (
    <FormContext {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle>
          <Box display="flex" alignItems="center" gridGap={8}>
            {device.is_silo && <DeviceIcon device={device} />} {device.device_name}
          </Box>

          <DialogContentText style={{ margin: 0 }}>
            <Box display="flex" alignItems="center" gridGap={8}>
              <PlantIcon /> {device.farm_name}
            </Box>
          </DialogContentText>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            autoFocus
            margin="dense"
            id="device_name"
            label={t('device_name')}
            fullWidth
            name="device_name"
            disabled={editContentOnly || updating}
            inputRef={form.register({
              required: <Trans i18nKey="required_field">Required Field</Trans>
            })}
            error={!!form.errors.device_name}
            helperText={form.errors.device_name && form.errors.device_name.message}
          />
          <Box
            mb={2}
            style={{
              // Hide the field, but keep it so that the form has the correct values
              display: editContentOnly ? 'none' : 'block'
            }}
          >
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item xs={12} md={8}>
                <NanolikeDatePicker
                  name="device_install_date"
                  value={device.device_install_date as Moment}
                  label={t('device_starting_date')}
                  disabled={true}
                  disableFuture
                />
              </Grid>
              {!device.is_silo && (
                <>
                  <Grid item xs={12} md={4}>
                    <NanolikeDatePicker
                      name="device_fabrication_date"
                      value={device.device_fabrication_date as Moment}
                      label={t('device_fabrication_date')}
                      disabled={updating}
                      disableFuture
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <NanolikeDatePicker
                      name="device_next_maintenance_date"
                      value={device.device_next_maintenance_date as Moment}
                      label={t('device_next_maintenance_date')}
                      disabled={updating}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <MultipleAutoComplete label={t('assign_group')} name="groups" options={groupOptions} />
          </Box>
          {device.is_silo ? (
            <SingleAutoComplete
              label={t('device_content')}
              name="product"
              options={productsOptions}
              disableClearable={false}
              helperText={
                hasAutoOrder && dirtyFields.has('product') ? (
                  <span style={{ color: theme.palette.warning.main }}>
                    {t('warning_auto_orders_impacted')}
                  </span>
                ) : null
              }
            />
          ) : (
            <FormControlLabel
              control={
                <Controller
                  control={form.control}
                  name="galileou_enabled"
                  as={({ onChange, value }) => (
                    <Checkbox onChange={e => onChange(e.target.checked)} checked={value} />
                  )}
                />
              }
              label={
                <ListItemText
                  primary={t('automaticPoiAssignationPrimaryText')}
                  secondary={t('automaticPoiAssignationSecondaryText')}
                />
              }
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} startIcon={<CancelIcon />}>
            {t('cancel')}
          </Button>
          <Button type="submit" color="primary" startIcon={<SaveIcon />}>
            {t('save')}
          </Button>
        </DialogActions>
      </form>
    </FormContext>
  );
};

export default DeviceForm;
