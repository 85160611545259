import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { ReactComponent as Thermometer } from 'shared/icons/Thermometer.svg';
import { IGraphData } from 'shared/model/api.model';
import { useTranslation } from 'react-i18next';

export const styles = makeStyles((theme: Theme) =>
  createStyles({
    temperature: {
      textAlign: 'center',
      color: '#2E48A7'
    },
    icon: {
      height: '90px'
    }
  })
);

export interface ITemperatureMetricProps {
  graphData: IGraphData | undefined;
}

const TemperatureMetric = (props: ITemperatureMetricProps) => {
  const { graphData } = props;
  const classes = styles();
  const { t } = useTranslation();

  if (!graphData || graphData.data_points.length === 0) {
    return null;
  }

  return (
    <Box className={classes.temperature}>
      <Box>
        <Thermometer className={classes.icon} />
      </Box>
      <Box>
        <Typography variant="h6">
          {t('data_type_unit', { value: graphData.data_points[0] })}
        </Typography>
      </Box>
    </Box>
  );
};

export default TemperatureMetric;
