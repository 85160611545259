import { useTheme } from '@material-ui/core';
import React, { ComponentProps, useMemo, useCallback, useEffect } from 'react';
import NanolikeMap from './nanolikeMap';

type Circle = {
  lat: number;
  lng: number;
  radius: number;
};

/**
 * A NanolikeMap that allows to draw a circle on it.
 *
 * For simplicity, does not allow to update the circle through props.
 */
const NanolikeCircleMap = ({
  circle,
  onCircleChanged,
  ...props
}: ComponentProps<typeof NanolikeMap> & {
  circle: Circle | null;
  onCircleChanged: (circle: Circle) => void;
}) => {
  const theme = useTheme();
  const circleMarkerRef = React.useRef<google.maps.Circle>();
  const [map, setMap] = React.useState<google.maps.Map>();

  const circleOptions = useMemo(
    () => ({
      fillColor: theme.palette.info.main,
      fillOpacity: 0.3,
      strokeColor: theme.palette.info.main,
      strokeWeight: 2,
      clickable: false,
      editable: true,
      zIndex: 1
    }),
    [theme]
  );

  // Avoid the need for parent to use useCallback
  const onCircleChangedRef = React.useRef(onCircleChanged);
  onCircleChangedRef.current = onCircleChanged;

  const onCircleMarkerUpdated = useCallback(() => {
    if (!circleMarkerRef.current) return;
    onCircleChangedRef.current?.({
      lat: circleMarkerRef.current.getCenter().lat(),
      lng: circleMarkerRef.current.getCenter().lng(),
      radius: circleMarkerRef.current.getRadius()
    });
  }, []);

  // Initialize the map
  useEffect(() => {
    const maps = window.google?.maps;
    if (!map || !maps) return;

    const drawingManager = new maps.drawing.DrawingManager({
      drawingControlOptions: {
        position: maps.ControlPosition.TOP_CENTER,
        drawingModes: [maps.drawing.OverlayType.CIRCLE]
      },
      circleOptions
    });

    drawingManager.setMap(map);

    // Listen to updates from a circle marker
    function bindCircleMarkerEvents(circleMarker: google.maps.Circle) {
      if (circleMarkerRef.current !== circleMarker) {
        // Clear previous circle from map
        circleMarkerRef.current?.setMap(null);
        circleMarkerRef.current = circleMarker;
      }
      circleMarker.addListener('radius_changed', onCircleMarkerUpdated);
      circleMarker.addListener('center_changed', onCircleMarkerUpdated);
    }

    // If initial circle, create it and zoom on it
    if (circle) {
      const circleMarker = new maps.Circle({
        ...circleOptions,
        map,
        radius: circle.radius,
        center: {
          lat: circle.lat,
          lng: circle.lng
        }
      });

      bindCircleMarkerEvents(circleMarker);
      map.fitBounds(circleMarker.getBounds());
    }

    // Listen to new circles being drawn
    const listener = maps.event.addListener(drawingManager, 'circlecomplete', circleMarker => {
      bindCircleMarkerEvents(circleMarker);
      onCircleMarkerUpdated();
    });

    return () => {
      circleMarkerRef.current?.setMap(null);
      drawingManager.setMap(null);
      listener.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, onCircleMarkerUpdated, theme, circleOptions]);

  return (
    <NanolikeMap
      {...props}
      onLoad={e => {
        props.onLoad?.(e);
        setMap(e.map);
      }}
    />
  );
};

export default NanolikeCircleMap;
