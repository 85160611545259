import { Box } from '@material-ui/core';
import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { Trans } from 'react-i18next';

const InstallerScreen = () => {
  // TODO : Add an Illustration
  return (
    <Box p={2}>
      <Alert severity="error">
        <Trans>error_unauthorzed_page</Trans>
      </Alert>
    </Box>
  );
};
export default InstallerScreen;
