import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { LocalShipping } from '@material-ui/icons';
import { IRootState } from 'config/store';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PrivateComponent from 'shared/auth/privateComponent';
import { APP_LOCAL_DATE_FORMAT, APP_TIMESTAMP_FORMAT, formatDate } from 'shared/utils/date-utils';
// import { getSupportEmail } from 'shared/utils/workspace-utils';
import DataTable from 'shared/widgets/dataTable';
import { newCalibrationsDeliveryTo, newCalibrationsLevelTo } from '.';
import { Edit } from '@material-ui/icons';
import DeliveryDialog from 'modules/devices/actions/deliveryDialog';
import ConfirmDelete from 'shared/widgets/confirmDelete';
import axios from 'axios';
import { getEnvApiUrl } from 'config/env';
import { DatePicker } from '@material-ui/pickers';
import { getGroupsDevices, useIsAuthorised } from 'shared/auth/auth-utils';
import { ReactComponent as CalibrateIcon } from 'shared/icons/CalibrateIcon.svg';
import { fetchCalibrationsInfo } from 'shared/reducers/calibrationsSlice';
import { ICalibration } from 'shared/model/calibration.model';
import CalibrationTypeFilter from './calibrationTypeFilter';
import UserAvatar from 'shared/widgets/userAvatar';
import CalibrateDialog from 'modules/devices/actions/calibrateDialog';
import GroupFilter from 'shared/widgets/groups/groupFilter';
import SearchIcon from '@material-ui/icons/Search';
import { ILabelValueOption } from 'shared/utils/select-utils';
import UserFilter from 'shared/widgets/users/userFilter';
import { capitalize, InputAdornment, makeStyles, TextField, Theme } from '@material-ui/core';
import { successNotification } from 'shared/reducers/notifierSlice';
import { IDevice } from 'shared/model/device.model';

const useStyles = makeStyles((theme: Theme) => ({
  searchField: {
    marginTop: 0,
    marginLeft: theme.spacing(2),
    width: 150,
    marginBottom: theme.spacing(1)
  }
}));

const handleDeleteDelivery = async (id: string) => {
  const apiUrl = getEnvApiUrl();
  await axios.delete(`${apiUrl}/internal/calibration-delivery/${id}`);
};
const handleDeleteLevel = async (id: string) => {
  const apiUrl = getEnvApiUrl();
  await axios.delete(`${apiUrl}/internal/calibration-level/${id}`);
};

const Calibrations = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const calibrations = useSelector(({ calibrations }: IRootState) => calibrations.calibrations);
  const allGroups = useSelector(({ group }: IRootState) => group.groups);
  const devices = getGroupsDevices(allGroups);
  const loading = useSelector(({ calibrations }: IRootState) => calibrations.loading);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [selectedRows, setSelectedRows] = useState<ICalibration[]>([]);
  const [openDeliveryEditionDialog, setOpenDeliveryEditionDialog] = useState(false);
  const [openLevelEditionDialog, setOpenLevelEditionDialog] = useState(false);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [startDate, setStartDate] = useState(() => {
    const savedDate = sessionStorage.getItem('startCalibFilterDate');
    return savedDate ? moment(savedDate) : moment().subtract(2, 'month').startOf('day');
  });
  const [typeFilter, setTypeFilter] = useState<'delivery' | 'level' | 'null'>('null');
  const [groupsFilter, setGroupsFilter] = useState<string[]>([]);
  const [usersFilter, setUsersFilter] = useState<string[]>([]);
  const columns: IDataTableColumn<ICalibration>[] = useMemo(
    () => [
      {
        selector: 'device_name',
        name: t('silo'),
        sortable: true,
        grow: 1,
        format: (row: ICalibration) => (
          <Box fontWeight="fontWeightBold">
            <Box key={row.idDevice}>{row.device_name}</Box>
          </Box>
        )
      },
      {
        selector: 'poi_name',
        name: t('farm_name_silo_industry'),
        sortable: true,
        grow: 1
      },
      {
        selector: 'date',
        name: t('calibration.date'),
        grow: 1,
        sortable: true,
        format: (row: ICalibration) => formatDate(row.calibration_date, APP_TIMESTAMP_FORMAT)
      },
      {
        selector: 'tonnage',
        name: t('string_workspace_filling_unit', { value: t('orders.tonnage') }),
        grow: 1,
        center: true,
        sortable: true,
        hide: 'md',
        format: (row: ICalibration) => t('number_workspace_filling_unit', { value: row.quantity }),
        sortFunction: (a, b) => {
          if (isNaN(a.quantity) || isNaN(b.quantity)) return 0; // Gérer les valeurs non numériques
          return a.quantity - b.quantity;
        }
      },
      {
        selector: 'type',
        name: t('resourcetype'),
        grow: 1,
        center: true,
        sortable: true,
        hide: 'md',
        format: (row: ICalibration) => t(`orders.${row.type}`)
      },
      {
        selector: 'created_at',
        name: t('created_at'),
        grow: 1,
        center: true,
        sortable: true,
        hide: 'md',
        format: (row: ICalibration) => {
          return formatDate(row.created_at, APP_TIMESTAMP_FORMAT);
        }
      },
      {
        selector: 'by',
        name: t('user'),
        grow: 1,
        center: true,
        sortable: true,
        hide: 'md',
        format: (calibration: ICalibration) => {
          const isExport = ['external', 'export'].includes(calibration.source);
          return (
            <Box display="flex">
              <UserAvatar
                user={
                  isExport
                    ? { first_name: capitalize(calibration.source) }
                    : calibration?.created_by
                }
                key={calibration.idCalibration}
                withTooltip
              />
            </Box>
          );
        }
      }
    ],
    [t]
  );

  useEffect(() => {
    sessionStorage.setItem('startCalibFilterDate', startDate.toISOString());
  }, [startDate]);

  const data = useMemo(() => {
    const result = calibrations.reduce((agg: ICalibration[], current: ICalibration) => {
      if (
        filterText.length === 0 ||
        current.device_name.toLowerCase().includes(filterText.toLowerCase())
      ) {
        agg.push(current);
      }
      return agg;
    }, [] as ICalibration[]);
    return result;
  }, [calibrations, filterText]);

  useEffect(() => {
    dispatch(fetchCalibrationsInfo(startDate, typeFilter, groupsFilter, usersFilter));
  }, [dispatch, startDate, typeFilter, groupsFilter, usersFilter]);

  const onSucess = () => {
    dispatch(fetchCalibrationsInfo(startDate, typeFilter, groupsFilter, usersFilter));
    setToggleCleared(!toggleCleared);
  };

  const contextActions = selectedRows.length === 1 && (
    <Box style={{ gap: 8, display: 'flex' }}>
      <PrivateComponent resource="Calibrations" operation={['UPDATE']}>
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={() =>
            selectedRows[0].type === 'level'
              ? setOpenLevelEditionDialog(true)
              : setOpenDeliveryEditionDialog(true)
          }
        >
          <Edit />
        </Button>
      </PrivateComponent>
      <PrivateComponent resource="Calibrations" operation={['DELETE']}>
        <ConfirmDelete
          onConfirm={() =>
            selectedRows[0].type === 'level'
              ? handleDeleteLevel(selectedRows[0].idCalibration).then(_ => {
                  onSucess();
                  dispatch(successNotification(t('calibration.level_deleted')));
                })
              : handleDeleteDelivery(selectedRows[0].idCalibration).then(_ => {
                  onSucess();
                  dispatch(successNotification(t('calibration.delivery_deleted')));
                })
          }
          objectToReturn={selectedRows}
          size="small"
        />
      </PrivateComponent>
      <DeliveryDialog
        onSucess={onSucess}
        calibration={selectedRows[0]}
        open={openDeliveryEditionDialog}
        handleClose={() => setOpenDeliveryEditionDialog(false)}
      />

      <CalibrateDialog
        calibration={selectedRows[0]}
        device={
          devices.find(device => device.device_reference === selectedRows[0].idDevice) ||
          ({} as IDevice)
        }
        open={openLevelEditionDialog}
        handleClose={() => setOpenLevelEditionDialog(false)}
        onSucess={onSucess}
      />
    </Box>
  );
  const actions = (
    <PrivateComponent resource="Calibrations" operation={['CREATE']}>
      <Button
        style={{ maxHeight: 32 }}
        color="primary"
        variant="contained"
        component={Link}
        to={newCalibrationsDeliveryTo}
      >
        <LocalShipping />
      </Button>
      <Button
        style={{ maxHeight: 32 }}
        color="primary"
        variant="contained"
        component={Link}
        to={newCalibrationsLevelTo}
      >
        <CalibrateIcon fill="white" />
      </Button>
    </PrivateComponent>
  );

  const title = useMemo(() => {
    const onChange = (filter: string) => {
      setFilterText(filter);
      if (filter.length === 0) {
        setResetPaginationToggle(!resetPaginationToggle);
      }
    };
    const onTypeChange = (event: React.ChangeEvent<any>) => {
      setTypeFilter(event.target.value);
    };

    const onGroupsChange = (selection: ILabelValueOption<string>[]) => {
      const filter = selection.map(item => item.value) as string[];
      setGroupsFilter(filter);
    };
    const onUsersChange = (selection: ILabelValueOption<string>[]) => {
      const filter = selection.map(item => item.value) as string[];
      setUsersFilter(filter);
    };

    return (
      <>
        <Box display="flex" flexDirection="row" justifyContent="start">
          {t('calibration.title_short')}
          <TextField
            className={classes.searchField}
            placeholder={t('search')}
            autoFocus
            onChange={e => onChange(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="disabled" />
                </InputAdornment>
              )
            }}
          />
          <CalibrationTypeFilter type={typeFilter} onChange={onTypeChange} />
          <GroupFilter onChange={onGroupsChange} />
          <DatePicker
            // label={t('from')}
            disableToolbar
            value={startDate}
            onChange={(value: any) => setStartDate(value)}
            format={APP_LOCAL_DATE_FORMAT}
            disableFuture
            margin="none"
            variant="inline"
            style={{ marginBottom: 8, width: 120 }}
          />
          <UserFilter onChange={onUsersChange} />
          {/* <SourceFilter onChange={onSourceChange} /> */}
        </Box>
      </>
    );
  }, [resetPaginationToggle, startDate, t, typeFilter, classes]);

  return (
    <>
      <Box p={1}>
        <DataTable
          title={title}
          columns={columns}
          data={data}
          selectableRows={useIsAuthorised('Calibrations', ['DELETE', 'UPDATE'])}
          defaultSortField="date"
          defaultSortAsc={false}
          progressPending={loading}
          // expandOnRowClicked
          expandableRowsHideExpander
          onSelectedRowsChange={state => setSelectedRows(state.selectedRows)}
          actions={actions}
          contextActions={contextActions}
          clearSelectedRows={toggleCleared}
          pagination
          paginationRowsPerPageOptions={[100, 200]}
          paginationPerPage={100}
        />
      </Box>
    </>
  );
};

export default Calibrations;
