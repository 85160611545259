import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import React from 'react';
import { styled, Theme } from '@material-ui/core';
import { getNanolikeTheme } from 'config/theme';

// Load without workspace settings because we use static colors
const theme = getNanolikeTheme(null) as Theme;

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-default': {
    backgroundColor: theme.palette.primary.main
  },
  '&.notistack-MuiContent-success': {
    backgroundColor: theme.palette.success.main
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: theme.palette.error.main
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: theme.palette.info.main
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: theme.palette.warning.main
  }
}));

const NanoSnackbarProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export { NanoSnackbarProvider };
