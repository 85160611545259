import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import { IRootState } from 'config/store';
import { TFunction } from 'i18next';
import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { IAlert } from 'shared/model/alert.model';
import { IGroup } from 'shared/model/group.model';
import { IMe, IUser } from 'shared/model/user.model';
import { getDeviceLabel } from 'shared/utils/device-utils';
import { ILabelValueOption } from 'shared/utils/select-utils';
import { displayUser } from 'shared/utils/user-utils';
import { workspaceIsSilo } from 'shared/utils/workspace-utils';
import { getDeviceTypeOptions } from 'shared/widgets/devices/deviceType';
import AlertFormFirstStep from './alertFormFirstStep';
import AlertFormSecondStep from './alertFormSecondStep';
import AlertFormThirdStep from './alertFormThirdStep';
import { IAlertFormResponse } from './createOrEditAlertForm';
import { useLocalizedDataTypeWithUnit } from 'shared/utils/lang-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    },
    title: {
      textAlign: 'center'
    },
    stepper: {
      padding: '1rem',
      paddingBottom: '0.5rem'
    },
    content: {
      minWidth: '50vw',
      minHeight: '40vh',
      [theme.breakpoints.up('lg')]: {
        minWidth: '40vw'
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%'
      }
    },
    btnbar: {
      '&>*': {
        marginRight: theme.spacing(1)
      }
    },
    divider: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    btnDivider: {
      marginLeft: theme.spacing(2)
    }
  })
);

export interface IAlertFormProps {
  onSubmit: (responses: IAlertFormResponse) => void;
}

const AlertForm = (props: IAlertFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { localizedDataTypeWithUnit } = useLocalizedDataTypeWithUnit();
  const alert = useSelector(({ alerts }: IRootState) => alerts.alert);
  const groups = useSelector(({ group }: IRootState) => group.groups);
  const users = useSelector(({ users }: IRootState) => users.users);
  const me = useSelector(({ authentication }: IRootState) => authentication.me) as IMe;
  const [activeStep, setActiveStep] = useState<number>(0);
  const { id } = useParams<{ id: string }>();
  const isNew = id === 'new' ? true : false;
  const steps = [t('alerts_step1'), t('alerts_step2'), t('alerts_step3')];

  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isSilo = workspaceIsSilo(settings);

  const initialValues =
    !isNew && alert
      ? initDefaultValues(alert, groups, t, users, isSilo, localizedDataTypeWithUnit)
      : {
          recipients: [
            {
              label: displayUser(me),
              value: me.idUser,
              group: t('user', { count: 100 })
            }
          ],
          notification_strategy_names: isSilo ? ['email', 'push'] : ['email']
        };

  const form = useForm<IAlertFormResponse>({
    // mode: 'onChange',
    // @ts-ignore
    defaultValues: initialValues
  });

  const onSubmit = form.handleSubmit(props.onSubmit);

  const handleNext = async () => {
    const result = await form.triggerValidation();
    if (result) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = async () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const titleKey = isNew ? 'create_your_alert' : 'modify_your_alert';

  return (
    <FormContext {...form}>
      <form className={classes.root} onSubmit={onSubmit} autoComplete="off">
        <Box className={classes.title}>
          <Typography variant="h5">{t(titleKey)}</Typography>
        </Box>
        <Grid container justify="center" alignItems="center">
          <Grid item className={classes.content}>
            {!isSilo && (
              <Box>
                <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
                  {steps.map(label => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            )}
            <Box>
              <AlertFormFirstStep step={0} activeStep={activeStep} />
              <AlertFormSecondStep step={isSilo ? 0 : 1} activeStep={activeStep} />
              <AlertFormThirdStep step={isSilo ? 0 : 2} activeStep={activeStep} />
            </Box>
          </Grid>
        </Grid>
        <Divider variant="middle" className={classes.divider} />
        <Box display="flex" justifyContent="center" alignItems="center" className={classes.btnbar}>
          <Button
            type="button"
            component={Link}
            to="/alerts"
            startIcon={<CancelIcon />}
            variant="contained"
          >
            {t('cancel')}
          </Button>
          <Divider orientation="vertical" className={classes.btnDivider} />
          {!isSilo && (
            <>
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={handleBack}
                startIcon={<ArrowBackIcon />}
                disabled={activeStep === 0}
              >
                {t('prev')}
              </Button>
              <Button
                color="primary"
                type="button"
                variant="contained"
                onClick={handleNext}
                endIcon={<ArrowForwardIcon />}
                disabled={activeStep === steps.length - 1}
              >
                {t('next')}
              </Button>
            </>
          )}
          <Button
            type="submit"
            color="primary"
            startIcon={<SaveIcon />}
            disabled={!isSilo ? activeStep !== steps.length - 1 : false}
            variant="contained"
          >
            {t('save')}
          </Button>
        </Box>
      </form>
    </FormContext>
  );
};

export default AlertForm;

/**
 * Init form with default values if exists
 */
const initDefaultValues = (
  alert: IAlert,
  groups: IGroup[],
  t: TFunction,
  users: IUser[],
  isSilo: boolean,
  localizedDataTypeWithUnit: Function
) => {
  const devicesToCheck: ILabelValueOption[] = alert?.devices_to_check
    ? alert.devices_to_check.map(item => ({
        label: getDeviceLabel(item, isSilo),
        value: item.device_id,
        group: t('device', { count: 100 })
      }))
    : [];
  const groupsToCheck: ILabelValueOption[] = alert?.device_groups_to_check
    ? alert.device_groups_to_check.map(item => ({
        label: item.group_name,
        value: item.group_id,
        group: t('group')
      }))
    : [];

  const device_group_to_check = [...devicesToCheck, ...groupsToCheck];

  const data_type = {
    label: localizedDataTypeWithUnit(alert.data_type),
    value: alert.data_type
  };
  const geofencing_strategy = alert.geofencing_strategy
    ? {
        label: alert.geofencing_strategy === 'in' ? t('in') : t('out'),
        value: alert.geofencing_strategy
      }
    : undefined;

  const userRecipients = users.reduce((agg: ILabelValueOption[], user) => {
    if (alert.recipients_for_notifications_ids.includes(user.idUser)) {
      agg.push({
        label: displayUser(user),
        value: user.idUser,
        group: t('user', { count: 100 })
      });
    }
    return agg;
  }, []);

  const groupRecipients = groups.reduce((agg: ILabelValueOption[], group) => {
    if (alert.recipient_groups_for_notifications_ids.includes(group.group_id)) {
      agg.push({
        label: group.group_name,
        value: group.group_id,
        group: t('group', { count: agg.length })
      });
    }
    return agg;
  }, []);

  const recipients = userRecipients.concat(groupRecipients);
  const device_types = alert.device_types
    ? alert.device_types.map(deviceType => ({
        label: t(`${deviceType}`),
        value: deviceType
      }))
    : getDeviceTypeOptions(groups, t);

  const min_value = alert.min_value;
  const max_value = alert.max_value;

  return {
    alert_name: alert.alert_name,
    device_group_to_check,
    data_type,
    geofencing_strategy,
    geofencing_lat: alert.geofencing_lat,
    geofencing_lng: alert.geofencing_lng,
    geofencing_radius: alert.geofencing_radius,
    recipients,
    min_value,
    max_value,
    notification_strategy_names: alert.notification_strategy_names,
    device_types,
    aggregate_operation: alert.aggregate_operation
  };
};
