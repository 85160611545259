import { TextField } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';
import NanolikeMap from 'shared/widgets/map/nanolikeMap';

type Location = {
  lat: number;
  lng: number;
};

/**
 * A search box with Google Maps autocomplete and a map to show the selected location.
 */
const LocationAutocomplete = (props: {
  location?: Location | null;
  onLocationChanged: (location: Location | null) => void;
}) => {
  const { location } = props;
  const { t } = useTranslation();
  // use a state instead of a ref, to trigger useEffects properly
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>();
  const [map, setMap] = useState<google.maps.Map>();
  const markerRef = useRef<google.maps.Marker>();
  const mapEventListener = useRef<google.maps.MapsEventListener>();

  // Keep a reference to the callback to avoid triggering useEffects
  const onLocationChangedRef = useRef(props.onLocationChanged);
  onLocationChangedRef.current = props.onLocationChanged;

  // Show the current location as coordinates in the search box
  useEffect(() => {
    if (!inputRef || !location) return;
    inputRef.value = `${location.lat}, ${location.lng}`;
  }, [inputRef, location]);

  // Register the search box for Google Map autocomplete,
  // once Google Maps and the input are mounted
  useEffect(() => {
    if (!map || !window.google?.maps) return;
    if (!inputRef) return;
    if (mapEventListener.current) return;

    // Add Google Maps search completion to the input
    const searchBox = new window.google.maps.places.SearchBox(inputRef);
    mapEventListener.current = searchBox.addListener('places_changed', () => {
      const onLocationChanged = onLocationChangedRef.current;

      try {
        onLocationChanged(parseGeolocString(inputRef.value));
      } catch (err) {
        if (err instanceof ParseGeolocError) {
          const places = searchBox.getPlaces();
          onLocationChanged(places[0]?.geometry?.location.toJSON() || null);
        } else {
          onLocationChanged(null);
        }
      }
    });

    return () => {
      if (mapEventListener.current) {
        window.google.maps.event.removeListener(mapEventListener.current);
      }
    };
  }, [inputRef, map]);

  // Update markers on map based on current location
  useEffect(() => {
    if (!window.google?.maps) return;
    // Clear out old marker
    markerRef.current?.setMap(null);

    if (location) {
      // Zoom on new location
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(location);
      map?.fitBounds(bounds);
      map?.setZoom(16);

      // Add marker to map
      markerRef.current = new window.google.maps.Marker({
        position: location,
        map
      });
    }
  }, [location, map]);

  return (
    <>
      <TextField
        inputRef={input => {
          setInputRef(input);
        }}
        placeholder={t('searchAddressPH')}
        onFocus={() => {
          if (inputRef) {
            // Focus select all
            inputRef.focus();
            inputRef.setSelectionRange(0, inputRef.value.length);
          }
        }}
        InputProps={{
          startAdornment: <SearchIcon />
        }}
      />

      <NanolikeMap
        onLoad={e => {
          setMap(e.map);
        }}
        getMapOptions={maps => ({
          mapTypeControl: true,
          mapTypeControlOptions: {
            mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE, maps.MapTypeId.HYBRID]
          }
        })}
        height="350px"
      />
    </>
  );
};

class ParseGeolocError extends Error {
  constructor() {
    super('COULD_NOT_PARSE');
  }
}

function parseGeolocString(geoloc: string): Location {
  geoloc = geoloc.trim();
  // See https://www.regextester.com/93451
  // const regex = /([NSEW])?\s?(-)?(\d+(?:\.\d+)?)[°º:d\s]?\s?(?:(\d+(?:\.\d+)?)['’‘′:]?\s?(?:(\d{1,2}(?:\.\d+)?)(?:"|″|’’|'')?)?)?\s?([NSEW])?/i;
  const regex = /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/g;

  const match = geoloc.match(regex);
  if (!match) {
    throw new ParseGeolocError();
  }
  const latLng = match[0].split(',');
  return { lat: parseFloat(latLng[0]), lng: parseFloat(latLng[1]) };
}

export default LocationAutocomplete;
