import React, { ComponentProps, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip as MaterialChip } from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { ReactComponent as CalibrateIcon } from '../../../shared/icons/CalibrateIcon.svg';
import CalibrateDialog from 'modules/devices/actions/calibrateDialog';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Phone from '@material-ui/icons/Phone';
import DeliveryDialog from 'modules/devices/actions/deliveryDialog';
import { openChat, openHelpCenter } from '../../../shared/utils/chat.utils';
import { IDevice } from 'shared/model/device.model';
import { workspaceIsIbc } from 'shared/utils/workspace-utils';
import { useSelector } from 'react-redux';
import { IRootState } from 'config/store';
import { IMe } from 'shared/model/user.model';
import { useIsAuthorised } from 'shared/auth/auth-utils';
import { isOperationSuggested, OperationSuggestedChip } from './OperationSuggested';
import { isWarningCause, WarningCauseChip } from './WarningCause';

const DeliveryChip = ({ device }: { device: IDevice }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Chip
      label={t('missing_delivery')}
      color={theme.palette.info.main}
      contrastColor={theme.palette.info.light}
      icon={<LocalShippingIcon />}
      device={device}
      dialog={DeliveryDialog}
    />
  );
};

const LevelChip = ({ device }: { device: IDevice }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Chip
      label={t('missing_level')}
      color={theme.palette.info.main}
      contrastColor={theme.palette.info.light}
      icon={<CalibrateIcon />}
      device={device}
      dialog={CalibrateDialog}
    />
  );
};

const SupportChip = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isIbc = workspaceIsIbc(settings);
  const me = useSelector(({ authentication }: IRootState) => authentication.me) as IMe;
  return (
    <Chip
      label={t('contact_support')}
      color={theme.palette.error.main}
      icon={<Phone />}
      contrastColor={theme.palette.error.light}
      onClick={() => {
        isIbc ? openChat() : openHelpCenter(me);
      }}
    />
  );
};

const Chip = (
  props: Omit<ComponentProps<typeof MaterialChip>, 'color'> & {
    dialog?: React.FC<any>;
    device?: IDevice;
    color: string;
    contrastColor?: string;
  }
) => {
  const { color, contrastColor, onClick, dialog: CustomDialog, ...other } = props;

  const [open, setOpen] = useState(false);

  const StyledChip = withStyles({
    outlined: {
      color: color,
      border: `1px solid ${color}`,
      '&:hover': {
        backgroundColor: `${contrastColor ?? '#F3F5FC'} !important`
      }
    },
    icon: {
      color: props.color
    }
  })(MaterialChip);

  return (
    <>
      {CustomDialog && open && (
        <CustomDialog device={props.device} open={open} handleClose={() => setOpen(false)} />
      )}
      <StyledChip
        onClick={event => {
          if (onClick) onClick(event);
          if (CustomDialog) setOpen(true);
        }}
        {...other}
        variant="outlined"
        size="small"
      />
    </>
  );
};

/**
 *
 */
const DeviceStatusColumn = ({ device }: { device: IDevice }) => {
  const { status } = device;
  const { info_to_calibrate, operation_suggested, warning_cause } = device.metadata ?? {};
  const { t } = useTranslation();
  const hasCalibrationsAuth = useIsAuthorised('Calibrations', ['CREATE']);

  const requiresDelivery = status !== 'problem' && info_to_calibrate?.includes('delivery');
  const requiresLevel = status !== 'problem' && info_to_calibrate?.includes('level');

  // Voir la logique spécifiée
  // https://www.notion.so/nanolike/NTSK-3347

  if (['problem'].includes(status)) {
    if (isOperationSuggested(operation_suggested)) {
      return <OperationSuggestedChip operation_suggested={operation_suggested} />;
    } else {
      return <SupportChip />;
    }
  } else if (['ok', 'pending', 'calibrating'].includes(status)) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        style={{ gap: 5, paddingTop: 5, paddingBottom: 5 }}
      >
        {hasCalibrationsAuth && (requiresDelivery || requiresLevel) ? (
          <>
            {requiresDelivery && <DeliveryChip device={device} />}
            {requiresLevel && <LevelChip device={device} />}
          </>
        ) : (
          t(`devices_status.${status}.label`)
        )}

        {isWarningCause(warning_cause) && <WarningCauseChip warning_cause={warning_cause} />}
      </Box>
    );
  } else if (['calibration_problem', 'error', 'level_problem'].includes(status)) {
    return <SupportChip />;
  } else {
    return t(`devices_status.${status}.label`);
  }
};

export default DeviceStatusColumn;
