import { Dialog, Box } from '@material-ui/core';
import DeviceForm, { IDeviceFormResponse } from 'modules/devices/dialogs/DeviceForm';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IDevice } from 'shared/model/device.model';
import { updateDevice, fetchDevices, updateDevicesWithGroup } from 'shared/reducers/devicesSlice';
import Loading from 'shared/widgets/loading';

const EditDeviceContentDialog = ({
  device,
  onClose,
  onUpdated
}: {
  device?: IDevice;
  onClose: () => void;
  onUpdated: () => void;
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values: IDeviceFormResponse) => {
    setIsLoading(true);
    const payload: Partial<IDevice> = {
      device_id: device?.device_id?.trim(),
      device_content_id: values.product?.value?.trim() ?? null
    };
    await dispatch(updateDevice(payload));
    await dispatch(fetchDevices());
    await dispatch(updateDevicesWithGroup());
    setIsLoading(false);
    onUpdated();
  };

  if (!device) {
    return null;
  }

  return (
    <Dialog open={!!device} onClose={onClose} maxWidth="lg">
      {isLoading ? (
        <Box p={4}>
          <Loading />
        </Box>
      ) : (
        <DeviceForm device={device} onSubmit={handleSubmit} onCancel={onClose} editContentOnly />
      )}
    </Dialog>
  );
};

export default EditDeviceContentDialog;
