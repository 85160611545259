import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import isNumber from 'lodash/isNumber';
import React from 'react';
import './level.css';
import { useTranslation } from 'react-i18next';
import { getNanolikeTheme } from 'config/theme';

export const levelThresholds = [30, 50];

export const useLevelStyles = makeStyles((theme: Theme) =>
  createStyles({
    border: {
      border: '2px Solid',
      borderRadius: '4px'
    },
    legend: {
      textAlign: 'center'
    }
  })
);

const GraphLevel = ({
  levelPercent,
  legend,
  width = 80,
  height = 80
}: {
  levelPercent: any;
  width?: number;
  height?: number;
  legend?: string;
}) => {
  const classes = useLevelStyles();

  const levelInfo = getLevelInfo(levelPercent);
  const levelColor = !isNumber(levelPercent) ? 'unknown-level' : levelInfo.className;

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box>
        <BoxLevelSvg width={width} height={height} levelPercent={levelPercent} />
      </Box>

      {legend && (
        <Box className={classes.legend}>
          <Typography variant="h6" className={levelColor}>
            {legend}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export const BoxLevelSvg = ({
  width,
  height,
  levelPercent
}: {
  width: number;
  height: number;
  levelPercent: number;
}) => {
  const { t } = useTranslation();
  const classes = useLevelStyles();
  const levelInfo = getLevelInfo(levelPercent);
  const isUnknownLevel = !isNumber(levelPercent);
  const levelColor = isUnknownLevel ? 'unknown-level' : levelInfo.className;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      className={`${classes.border} ${levelColor}`}
    >
      {isUnknownLevel ? (
        <g>
          <rect
            id="40_percent"
            width="80"
            y="68"
            x="10"
            strokeWidth="15"
            height="2"
            fill="#cccccc"
          />
          <rect
            id="20_percent"
            width="80"
            y="88"
            x="10"
            strokeWidth="15"
            height="2"
            fill="#cccccc"
          />
          <text
            text-anchor="center"
            font-size="45"
            font-weight="bold"
            id="svg_3"
            y="50"
            x="40"
            stroke-width="0"
            fill="#cccccc"
          >
            ?
          </text>
        </g>
      ) : (
        <title>{`${t('level')}: ${levelPercent}%`}</title>
      )}
      <g>
        {levelPercent > 90 && (
          <rect
            className={levelColor}
            id="100_percent"
            width="80"
            y="9"
            x="10"
            strokeWidth="15"
            height="2"
          />
        )}
        {levelPercent > 70 && (
          <rect
            className={levelColor}
            id="80_percent"
            width="80"
            y="29"
            x="10"
            strokeWidth="15"
            height="2"
          />
        )}
        {levelPercent > 50 && (
          <rect
            className={levelColor}
            id="60_percent"
            width="80"
            y="49"
            x="10"
            strokeWidth="15"
            height="2"
          />
        )}
        {levelPercent > 30 && (
          <rect
            className={levelColor}
            id="40_percent"
            width="80"
            y="68"
            x="10"
            strokeWidth="15"
            height="2"
          />
        )}
        {levelPercent > 10 && (
          <rect
            className={levelColor}
            id="20_percent"
            width="80"
            y="88"
            x="10"
            strokeWidth="15"
            height="2"
          />
        )}
      </g>
    </svg>
  );
};

export const getLevelInfo = (level: number) => {
  const theme = getNanolikeTheme(null) as Theme; // we use non dynamic colors only here

  let className = 'green-level';
  let titleKey = 'high';
  let mapColorKey = theme.palette.success.main;
  if (level <= levelThresholds[0]) {
    className = 'red-level';
    titleKey = 'low';
    mapColorKey = theme.palette.error.main;
  } else if (level <= levelThresholds[1]) {
    className = 'orange-level';
    titleKey = 'medium';
    mapColorKey = theme.palette.warning.main;
  }
  return {
    className,
    titleKey,
    mapColorKey
  };
};

export default GraphLevel;
