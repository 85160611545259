import { IDevice } from 'shared/model/device.model';
import { DeviceAutoOrder, GroupAutoOrder, normalizeMaterialNo } from 'shared/model/autoOrder.model';
import { useSelector } from 'react-redux';
import { IRootState } from 'config/store';
import { IProduct } from 'shared/model/product.model';

/**
 * @returns The configured auto-order for this device, if it exists
 */
function useDeviceAutoOrder(device: IDevice): DeviceAutoOrder | undefined {
  const groupAutoOrders = useSelector(({ autoOrder }: IRootState) => autoOrder.groupAutoOrders);
  const groupAutoOrder = groupAutoOrders.find(gao => gao.group_id === device.farm_id);
  const deviceAutoOrder = groupAutoOrder?.device_auto_orders.find(
    dao => dao.device_id === device.device_id
  );
  return deviceAutoOrder;
}

/**
 * @returns The configured device auto-orders for this product
 */
function useProductAutoOrders(product: IProduct): DeviceAutoOrder[] {
  const groupAutoOrders = useSelector(({ autoOrder }: IRootState) => autoOrder.groupAutoOrders);
  return groupAutoOrders.flatMap(gao =>
    gao.device_auto_orders.filter(
      dao =>
        normalizeMaterialNo(dao.device_content_reference) ===
        normalizeMaterialNo(product.device_content_reference)
    )
  );
}

/**
 * @returns The configured auto-order for this group, if it exists
 */
function useGroupAutoOrder(groupId: string): GroupAutoOrder | undefined {
  const groupAutoOrders = useSelector(({ autoOrder }: IRootState) => autoOrder.groupAutoOrders);
  return groupAutoOrders.find(gao => gao.group_id === groupId);
}

export { useDeviceAutoOrder, useProductAutoOrders, useGroupAutoOrder };
